import AdvertisingToolsData from "./AdvertisingToolsData";
import BlogToolsData from "./BlogToolsData";
import CopywritingToolsData from "./CopywritingToolsData";
import EcommerceToolsData from "./EcommerceToolsData";
import EmailToolsData from "./EmailToolsData";
import ExtrasData from "./ExtrasData";
import FilmMakingToolsData from "./FilmMakingToolsData";
import HRToolsData from "./HRToolsData";
import IdeaGenerationData from "./IdeaGenerationData";
import ImagePromptsData from "./ImagePromptsData";
import MusicToolsData from "./MusicToolsData";
import RePurposeContentData from "./RePurposeContentData";
import SEOToolsData from "./SEOToolsData";
import SalesData from "./SalesData";
import SocialMediaToolsData from "./SocialMediaToolsData";
import SupportData from "./SupportData";
import WebsiteContentData from "./WebsiteContentData";
import WritingAssistantData from "./WritingAssistantData";
import YoutubeToolsData from "./YoutubeToolsData";
import EducationData from "./EducationToolsData";
import PodcastData from "./PodcastData";
import StoryData from "./StoryData"; 
import ChatToolsData from "./ChatToolsData";
import TravelData from "./TravelData";
import NameTitleData from "./NameTitleData";
import CareerToolsData from "./CareerToolsData";
const ToolsData = { 
    ...BlogToolsData,
    ...SocialMediaToolsData,
    ...AdvertisingToolsData, 
    ...YoutubeToolsData,  
    ...SEOToolsData,
    ...WritingAssistantData,
    ...CopywritingToolsData,
    ...WebsiteContentData,
    ...EcommerceToolsData,
    ...SalesData,
    ...EmailToolsData,
    ...IdeaGenerationData,
    ...HRToolsData,
    ...SupportData, 
    ...EducationData,
    ...ImagePromptsData,
    ...RePurposeContentData,
    ...FilmMakingToolsData,
    ...MusicToolsData, 
    ...PodcastData,   
    ...StoryData,
    ...TravelData,
    ...ExtrasData,  
    ...NameTitleData,     
    ...CareerToolsData                                                                                                                                                                                                                                                                                          
}


export default ToolsData;