import { 
  collection, 
  query, 
  orderBy, 
  onSnapshot, 
  doc, 
  deleteDoc, 
  updateDoc,
  setDoc, 
  writeBatch, 
  serverTimestamp,
  getDocs,
  where,
  getDoc
} from 'firebase/firestore';
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject, getMetadata } from 'firebase/storage';
import { storage, db } from '../../firebase';
  
  export const fetchChatById = async (chatId, userId) => {
    const chatRef = doc(db, 'chats', chatId);
    const docSnap = await getDoc(chatRef);
    
    if (!docSnap.exists() || docSnap.data().userId !== userId) {
      throw new Error('Chat not found or unauthorized');
    }
    
    return { id: docSnap.id, ...docSnap.data() };
  };
  
  export const listenToChats = (userId, onChatsUpdate, onError) => {
    const unsubscribe = onSnapshot(
      query(
        collection(db, 'chats'),
        where('userId', '==', userId),
        orderBy('metadata.updatedAt', 'desc')
      ),
      {
        next: (snapshot) => {
          const chatData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          onChatsUpdate(chatData);
        },
        error: (error) => {
          console.error('Error fetching chats:', error);
          onError('Failed to load chats: ' + error.message);
        }
      }
    );
    
    return unsubscribe;
  };
  
  export const listenToMessages = (chatId, onMessagesUpdate) => {
    const chatRef = doc(db, 'chats', chatId);
    const messagesRef = collection(chatRef, 'messages');
    
    const unsubscribe = onSnapshot(
      query(messagesRef, orderBy('timestamp', 'asc')),
      snapshot => {
        const messages = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        onMessagesUpdate(messages);
      }
    );
    
    return unsubscribe;
  };
  
  export const deleteChat = async (chatId, userId) => {
    const chatRef = doc(db, 'chats', chatId);
    const chatDoc = await getDoc(chatRef);
    
    if (!chatDoc.exists() || chatDoc.data().userId !== userId) {
      throw new Error('Unauthorized to delete this chat');
    }
  
    const batch = writeBatch(db);
    
    // Delete messages and their attachments
    const messagesRef = collection(chatRef, 'messages');
    const messagesSnapshot = await getDocs(messagesRef);
    
    const deletePromises = messagesSnapshot.docs.flatMap(doc => {
      const messageData = doc.data();
      batch.delete(doc.ref);
      
      return (messageData.attachments || []).map(attachment => 
        deleteObject(ref(storage, attachment.url))
          .catch(error => console.error('Error deleting file:', error))
      );
    });
  
    await Promise.all(deletePromises);
    
    // Delete chat document
    batch.delete(chatRef);
    await batch.commit();
  };
  
  export const createChat = async (text, userId, model) => {
    if (!userId || !text.trim()) return null;
  
    const chatRef = doc(collection(db, 'chats'));
    const timestamp = serverTimestamp();
    
    await setDoc(chatRef, {
      userId,
      metadata: {
        createdAt: timestamp,
        updatedAt: timestamp,
        model,
        title: text.substring(0, 30) + (text.length > 30 ? '...' : ''),
        lastMessage: {
          text,
          timestamp
        },
        agent: null, // Initialize agent field
        systemPrompt: "", // Initialize system prompt field
      },
      participants: {
        user: {
          role: 'user',
          joinedAt: timestamp,
          userId
        }
      }
    });
    
    return chatRef.id;
  };
  
  export const sendMessage = async ({ 
    text, 
    files = [], 
    chatId, 
    userId, 
    model, 
    systemPrompt, 
    refreshSubscription, 
    onInsufficientCredits 
  }) => {
    const chatRef = doc(db, 'chats', chatId);
    const messagesRef = collection(chatRef, 'messages');
    const batch = writeBatch(db);
    const timestamp = serverTimestamp();
  
    try {
      // Get chat metadata and agent files
      const chatDoc = await getDoc(chatRef);
      const chatData = chatDoc.data();
      let agentFiles = chatData?.metadata?.agentFiles || [];
  
      // Validate and filter agent files that still exist
      agentFiles = (await Promise.all(
        agentFiles.map(async file => {
          try {
            const fileRef = ref(
              storage, 
              file.path || `agents/${chatData.metadata.agent.id}/files/${file.name}`
            );
            await getMetadata(fileRef);
            return file;
          } catch (error) {
            console.warn(`File not found, removing from list: ${file.name}`);
            return null;
          }
        })
      )).filter(file => file !== null);
  
      // Update chat metadata with filtered files
      await updateDoc(chatRef, {
        'metadata.agentFiles': agentFiles
      });
  
      // Upload new files from this message
      const uploadedFiles = await Promise.all(
        files.map(async file => {
          const storageRef = ref(storage, `uploads/${chatId}/${Date.now()}_${file.name}`);
          await uploadBytes(storageRef, file);
          const url = await getDownloadURL(storageRef);
          
          return {
            name: file.name,
            url,
            type: file.type,
            size: file.size
          };
        })
      );
  
      // Batch user message creation
      batch.set(doc(messagesRef), {
        text: text || 'Analyze this document',
        timestamp,
        sender: 'user',
        userId,
        attachments: uploadedFiles,
        agentFiles
      });
  
      // Batch metadata update
      batch.update(chatRef, {
        'metadata.lastMessage': {
          text: text || 'Analyzed document',
          timestamp
        },
        'metadata.updatedAt': timestamp,
        'metadata.model': model
      });
  
      // Commit batch operations
      await batch.commit();
  
      // Make API request to chat endpoint
      const dev = 'https://wk-server.vercel.app/api/chat';
      const local = 'http://localhost:3001/api/chat';
      const aiResponse = await fetch(dev, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userId}`
        },
        credentials: 'include',
        body: JSON.stringify({
          message: text,
          files: [...uploadedFiles, ...agentFiles],
          model,
          chatId,
          userId,
          systemPrompt,
          metadata: {
            isAgentChat: chatData?.metadata?.isAgentChat ?? false,
            agentName: chatData?.metadata?.agent?.name || null
          }
        })
      });
  
      // Handle non-OK response
      if (!aiResponse.ok) {
        throw new Error(await aiResponse.text());
      }
  
      // Process response and update word count
      const responseText = await aiResponse.text();
      const wordCount = responseText.trim().split(/\s+/).length;
  
      // Update user's total word count
      const userDataRef = doc(db, 'userData', userId);
      const userDataSnap = await getDoc(userDataRef);
  
      if (userDataSnap.exists()) {
        const currentTotalWords = userDataSnap.data().totalWords || 0;
        const newTotalWords = currentTotalWords + wordCount;
  
        // Debounce the word count update
        await updateDoc(userDataRef, {
          totalWords: newTotalWords
        });
        
        console.log({
          wordCount,
          currentTotalWords,
          newTotalWords
        });
      }
  
      return {
        uploadedFiles,
        wordCount,
        success: true
      };
  
    } catch (error) {
      console.error('Error in sendMessage:', error);
      throw error;
    }
  };