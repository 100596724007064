import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Nav, Accordion, Container, NavLink, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router";
import toast, { Toaster } from 'react-hot-toast'; 
import { useLocation  } from "react-router-dom";
import { Link } from "react-router-dom";
import TabContainer from 'react-bootstrap/TabContainer'
import { useUserAuth } from "../../context/UserAuthContext";   
import { getFunctions, httpsCallable } from "firebase/functions";   
import Loader from "../shared/utilities/loader/Loader";
import axios from "axios";
    import "./Pricing.css"; 
import Loader2 from "../shared/utilities/loader2/Loader2";


let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51Kzc9vSJsVlrtsJgpAfNUX5BUXA8Rb3uKyIEFtFaSibM5rNeaXVRvCOiOXBic7GN2MD4SGliDtWJ8kZT5R6HhNz800iag3rG8U");
  }

  return stripePromise;
};

const Pricing = () => {  
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [inPage, SetInPage] = useState(false);

  useEffect(() => { 
    if (location.pathname.includes('/pricing')) {
      SetInPage(true);
    } else {
      SetInPage(false);
    }
  }, [location]);


  const { user } = useUserAuth();
  const [planObjMonth, setPlanObjMonth] = useState([
    {planType: 1, planPrice: "590", planWords: "15,000", planPriceID:"price_1M1UGrSJsVlrtsJga8yIsBNe"},
    {planType: 2, planPrice: "1,890", planWords: "50,000", planPriceID:"price_1M1UKVSJsVlrtsJgQhvArPD2"},
    {planType: 3, planPrice: "2,490", planWords: "100,000", planPriceID:"price_1M1ULOSJsVlrtsJg4KBtD5KW"},
    {planType: 4, planPrice: "3,490", planWords: "200,000", planPriceID:"price_1M1ULvSJsVlrtsJgxiDhzLOm"},
    {planType: 5, planPrice: "7,990", planWords: "500,000", planPriceID:"price_1M1UMWSJsVlrtsJggL1Xb9G8"},
    {planType: 6, planPrice: "14,990", planWords: "1,00,0000", planPriceID:"price_1M1UMwSJsVlrtsJgY6fQkdIG"}
  ]) 
  const [planObjYear, setPlanObjYear] = useState([
    {planType: 1, planPrice: "390", planWords: "15,000", planPriceID:"price_1M1UV4SJsVlrtsJga0iztLqi", planAnnualPrice: "4680"},
    {planType: 2, planPrice: "1,260", planWords: "50,000", planPriceID:"price_1M1UW6SJsVlrtsJgnj7p5kA8", planAnnualPrice: "15120"},
    {planType: 3, planPrice: "1,660", planWords: "100,000", planPriceID:"price_1M1UWOSJsVlrtsJgbJIXghhs", planAnnualPrice: "19920"},
    {planType: 4, planPrice: "2,320", planWords: "200,000", planPriceID:"price_1M1UWiSJsVlrtsJgjURAKbdj", planAnnualPrice: "27840"},
    {planType: 5, planPrice: "5,320", planWords: "500,000", planPriceID:"price_1M1UX4SJsVlrtsJgJjrVVy2y", planAnnualPrice: "63840"},
    {planType: 6, planPrice: "9,990", planWords: "1,00,0000", planPriceID:"price_1M1UXiSJsVlrtsJgN8GPPl2I", planAnnualPrice: "119880"}
  ])
  const [planObjMonthUs, setPlanObjMonthUs] = useState([
    {planType: "pmu1", planPrice: "7", planWords: "15,000", planPriceID:"price_1M1UgPSJsVlrtsJgy7RDzxWU"},
    {planType: "pmu2", planPrice: "19", planWords: "50,000", planPriceID:"price_1M1UgPSJsVlrtsJgEPBjLXpy"},
    {planType: "pmu3", planPrice: "29", planWords: "100,000", planPriceID:"price_1M1UgPSJsVlrtsJgBz3mg6pk"},
    {planType: "pmu4", planPrice: "39", planWords: "200,000", planPriceID:"price_1M1UgPSJsVlrtsJg1M89Bxg5"},
    {planType: "pmu5", planPrice: "96", planWords: "500,000", planPriceID:"price_1M1UgPSJsVlrtsJgF587O7HM"},
    {planType: "pmu6", planPrice: "179", planWords: "1,00,0000", planPriceID:"price_1M1UgQSJsVlrtsJgwu0xpz39"}
  ]) 
  const [planObjYearUs, setPlanObjYearUs] = useState([
    {planType: "pyu1", planPrice: "5", planWords: "15,000", planPriceID:"price_1M1UkxSJsVlrtsJgPH0mQRJ6", planAnnualPrice: "60"},
    {planType: "pyu2", planPrice: "12", planWords: "50,000", planPriceID:"price_1M1UkxSJsVlrtsJgFBBImmgT", planAnnualPrice: "144"},
    {planType: "pyu3", planPrice: "19", planWords: "100,000", planPriceID:"price_1M1UkxSJsVlrtsJgDn8wXltZ", planAnnualPrice: "228"},
    {planType: "pyu4", planPrice: "26", planWords: "200,000", planPriceID:"price_1M1UkxSJsVlrtsJgiuL4wqey", planAnnualPrice: "312"},
    {planType: "pyu5", planPrice: "64", planWords: "500,000", planPriceID:"price_1M1UkySJsVlrtsJgTvyn25LA", planAnnualPrice: "768"},
    {planType: "pyu6", planPrice: "119", planWords: "1,00,0000", planPriceID:"price_1M1UkySJsVlrtsJg8T1AU8PC", planAnnualPrice: "1428"}
  ])
  const [selectedPlan, setSelectedPlan] = useState(1)
  const navigate = useNavigate(); 
  const functions = getFunctions(); 
  const [key, setKey] = useState('monthly');
  const [state, setState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: ""
  });
  
  //const stripe =    window.Stripe('pk_test_51Kzc9vSJsVlrtsJgysz6iWCYrNBieshzuXYk4OAdYr83Ip7u3iAfWLGYmiCWG5xY9mm703ARAjXIRtNo1UQsUlAz00vQq96pB1');
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/") 
      .then((response) => {
        let data = response.data;
        setState({
          ...state, 
          countryName: data.country_name,
          countryCode: data.country_code, 
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const priceRange = (e) => {
    const range = e.target.value - 1;
    // //console.log(range)
    // //console.log(planObj[range].planPrice)
    let selPlan = parseInt(range);
    setSelectedPlan(selPlan);
  }
  const checkOut = async (e) => { 
    const priceId = e.target.id;
    const stripe = await getStripe();
    setLoading(true);
   // //console.log("clicked" + priceId) 
    const getCheckoutSession = httpsCallable(functions, 'createStripeCheckout'); 
    const param = {
      uid: user.uid,
      priceId: priceId
    }
    getCheckoutSession(param).then(async (response) => {  
          const sessionId = response.data.id;
          await stripe.redirectToCheckout({sessionId: sessionId})
          setLoading(false);
    }); 
  }
  const cancelSub = async () => { 
    const stripe = await getStripe();
    
    const delSub = httpsCallable(functions, 'deleteStripeSubscription');
    const param = {
      subId : 'subId'
    }
    delSub(param).then((response) => {
      //console.log(response)
    })  
  }
  useEffect(() => {
    getGeoInfo();
  }, []);
  return (
    <>   
      <div className='page-layout'> 
            <div className="header-txt sub-head text-center"> 
                <h2>Pricing</h2> 
                <p>Upgrade to Premium plan and create more high quality content for your Blog, Website and Social Media AD's</p> 
                {/* <p>Country Name: {state.countryName}</p> */}
                {/* <Button variant="primary" size="md" className="w-100 buttonWrap" id="price_1L5uxqSJsVlrtsJg2PTQyZDr" onClick={checkOut}>Upgrade Now - Day 99</Button> */}
            </div>  
            <div className="container">  

               <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3 pricingTabs"
    > 
      <Tab eventKey="monthly" title="Monthly">
      <div className="loaderInline">{loading && <Loader2/>}</div> 
      <div className="row">
      <div className="col-md-7"> 
<div className="card pricing-box d-flex mx-auto activebox">
    <div className="card-body">
        <h3 className="card-title">Professional</h3>
        <h5 className="card-subtitle mb-1">
        {state.countryName == "India" && <>&#8377;{planObjMonth[selectedPlan].planPrice}</>}
        {state.countryName !== "India" && <>&#36;{planObjMonthUs[selectedPlan].planPrice}</>}
           <span className="ml-3">Per month</span></h5> 
           {/* <small className="text-muted">Cancel anytime. Billed monthly.</small> */}
        <div>
        {/* {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L65YgSJsVlrtsJgA2ls859w" onClick={checkOut}>Upgrade Now</Button>}
        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L4mmsSJsVlrtsJg2EA66GrC" onClick={checkOut}>Upgrade Now</Button>} */}
        {/* Live */}
        <div className="planWords mt-2">
            <strong>{planObjMonth[selectedPlan].planWords}</strong> words
          </div>
        <div className="rangeWrap"> 
          <div className="rangeScale"><span></span><span></span><span></span><span></span><span></span><span></span></div>
          <input type="range" min="1" max="6" className="slider" value={selectedPlan + 1} onChange={priceRange}/></div>
          
          {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id={planObjMonth[selectedPlan].planPriceID} onClick={checkOut}>Upgrade Now</Button>}
          {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id={planObjMonthUs[selectedPlan].planPriceID} onClick={checkOut}>Upgrade Now</Button>}
        </div>
        <div className="card-text my-3">
                <ul> 
                    <li><i className="tickMark">✔</i> <strong>{planObjMonth[selectedPlan].planWords} words</strong></li>
                    <li><i className="tickMark">✔</i> Access to all 435+ AI tools</li>
                    <li><i className="tickMark">✔</i> Access to all 78 pre-build templates</li>
                    <li><i className="tickMark">✔</i> Image Generator</li> 
                    <li><i className="tickMark">✔</i> Quick Long-Form Article Writing Tool</li> 
                    <li><i className="tickMark">✔</i> SEO - Keyword Research</li> 
                    <li><i className="tickMark">✔</i> Multilingual support</li>
                    <li><i className="tickMark">✔</i> 24/7 email support</li>
                </ul>
            </div>
    </div>
</div>

</div> 
            <div className="col-md-5">

                <div className="card pricing-box d-flex mx-auto">
                <div className="card-body">
                        <h3 className="card-title">Enterprise</h3>
                        <h5 className="card-subtitle-custom mb-1">
                        Customized </h5> 
                        <div> 
                        <div className="planWords mt-2 mb-2">
                          Monthly package</div>
                        <div className="planWords mt-2 mb-3"><strong><i>Looking for unlimited words?</i></strong></div>
                        <Button variant="primary-outline" disabled={loading} size="md" className="w-100 buttonWrap" target="_blank" href="https://wordkraft.ai/support/">Get in touch</Button>
                         </div>
                        <div className="card-text my-3">
                                <ul> 
                                    <li><i className="tickMark">✔</i> Includes professional plan</li> 
                                    <li><i className="tickMark">✔</i> <strong className="mr-1">Unlimited</strong> words</li>
                                    <li><i className="tickMark">✔</i> Customized tools</li>                                    
                                    <li><i className="tickMark">✔</i> Customized templates</li>
                                    <li><i className="tickMark">✔</i> Dedicated manager</li>
                                    <li><i className="tickMark">✔</i> Technical support</li>
                                    <li><i className="tickMark">✔</i> Article writing support</li>  
                                </ul>
                            </div> 
                    </div>
              
                </div>

            </div>
            
            {/* <div className="col-sm-4">
                <div className="card pricing-box d-flex mx-auto">
                    <div className="card-body">
                        <h3 className="card-title">Expert</h3>  
                        <h5 className="card-subtitle mb-1">
                        {state.countryName == "India" && <>&#8377;9900 </>}
                        {state.countryName !== "India" && <>&#36;149</>}
                           <span>Per month</span></h5> <small className="text-muted">Cancel anytime. Billed monthly.</small>
                        <div>
                        {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L65aJSJsVlrtsJgyvD8KrJ7" onClick={checkOut}>Upgrade Now</Button>}
                        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L4mnhSJsVlrtsJgeQiVaE9j" onClick={checkOut}>Upgrade Now</Button>}
                          </div>
                        <div className="card-text text-muted my-3">
                                <ul> 
                                <li><i className="tickMark">✔</i> Unlimited words per month</li>
                                    <li><i className="tickMark">✔</i> Access to all 44 tools</li>
                                    <li><i className="tickMark">✔</i> Quick Long-Form Article Writing Tool</li> 
                                    <li><i className="tickMark">✔</i> Multilingual support</li>
                                    <li><i className="tickMark">✔</i> 24/7 email support</li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div> */}
           </div>
      </Tab>
      <Tab eventKey="yearly" title="Yearly">
      <div className="loaderInline">{loading && <Loader2/>}</div> 
      <div className="row">
        
      <div className="col-md-7">
                <div className="card pricing-box d-flex mx-auto activebox">
                    <div className="card-body">
                    <div className="offerTxt">40 % off</div>
                        <h3 className="card-title">Professional</h3>
                        <h5 className="card-subtitle mb-1">
                        {state.countryName == "India" && <>&#8377;{planObjYear[selectedPlan].planPrice} 
                        <span className="ml-3"> Per month. Billed &#8377;{planObjYear[selectedPlan].planAnnualPrice} yearly.</span>
                        </>}
                        {state.countryName !== "India" && <>&#36;{planObjYearUs[selectedPlan].planPrice} 
                        <span className="ml-3"> Per month. Billed &#36;{planObjYearUs[selectedPlan].planAnnualPrice} yearly.</span>
                        </>}
                            </h5> 
                           {/* <small className="text-muted">Cancel anytime. Billed yearly.</small> */} 
                           <div className="planWords mt-2">
                            <strong>{planObjYear[selectedPlan].planWords}</strong> words
                          </div>
                          <div className="rangeWrap"> 
                          <div className="rangeScale"><span></span><span></span><span></span><span></span><span></span><span></span></div>
                          <input type="range" min="1" max="6" className="slider" value={selectedPlan + 1} onChange={priceRange}/></div>
                        <div>
                        {/* {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L65g8SJsVlrtsJgHOxyXDAV" onClick={checkOut}>Upgrade Now</Button>}
                        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L5jIvSJsVlrtsJgwOkuXJLs" onClick={checkOut}>Upgrade Now</Button>} */}
                        {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id={planObjYear[selectedPlan].planPriceID} onClick={checkOut}>Upgrade Now</Button>}
                        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id={planObjYearUs[selectedPlan].planPriceID} onClick={checkOut}>Upgrade Now</Button>}
                         
                          </div>
                        <div className="card-text my-3">
                                <ul> 
                                    <li><i className="tickMark">✔</i> <strong>{planObjYear[selectedPlan].planWords}  words</strong></li>
                                    <li><i className="tickMark">✔</i> Access to all 64 tools</li>
                                    <li><i className="tickMark">✔</i> Access to all 78 pre-build templates</li>
                                    <li><i className="tickMark">✔</i> Image Generator</li>
                                    <li><i className="tickMark">✔</i> Quick Long-Form Article Writing Tool</li> 
                                    <li><i className="tickMark">✔</i> SEO - Keyword Research</li> 
                                    <li><i className="tickMark">✔</i> Multilingual support</li>
                                    <li><i className="tickMark">✔</i> 24/7 email support</li>
                                </ul> 
                            </div>
                    </div>
                </div>
            </div> 
            <div className="col-md-5">
                <div className="card pricing-box d-flex mx-auto">
                <div className="card-body">
                        <h3 className="card-title">Enterprise</h3>
                        <h5 className="card-subtitle-custom mb-1">
                        Customized </h5> 
                        <div> 
                        <div className="planWords mt-2 mb-2">
                          Yearly package</div>
                        <div className="planWords mt-2 mb-3"><strong><i>Looking for unlimited words?</i></strong></div>
                       <Button variant="primary-outline" disabled={loading} size="md" className="w-100 buttonWrap" target="_blank" href="https://wordkraft.ai/support/">Get in touch</Button>
                         </div>
                        <div className="card-text my-3">
                                <ul> 
                                    <li><i className="tickMark">✔</i> Includes professional plan</li> 
                                    <li><i className="tickMark">✔</i> <strong className="mr-1">Unlimited</strong> words</li>
                                    <li><i className="tickMark">✔</i> Customized tools</li>                                    
                                    <li><i className="tickMark">✔</i> Customized templates</li> 
                                    <li><i className="tickMark">✔</i> Dedicated manager</li>
                                    <li><i className="tickMark">✔</i> Technical support</li>
                                    <li><i className="tickMark">✔</i> Article writing support</li> 
                                </ul>
                            </div> 
                    </div>
                </div>
            </div> 
           </div>
      </Tab> 
    </Tabs>


          

        <div className="row mt-4 faqs">
            <div className="col-md-12">
                <div className="my-4 text-center">
                <h3>FAQ's</h3>
                <div>Frequently asked questions, answered.</div>
                </div>
            <Accordion defaultActiveKey="0" flush >
  <Accordion.Item eventKey="0" className="rounded mb-3">
    <Accordion.Header>How can I contact support?</Accordion.Header>
    <Accordion.Body>
    You can click on the chat button to have a word with our support team. If you prefer email, drop us a line at support@wordkraft.ai
   </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1" className="rounded mb-3">
    <Accordion.Header>Are there any video tutorials I can refer to?</Accordion.Header>
    <Accordion.Body>
    Yes, we have a bunch of them on <a href="https://www.youtube.com/@wordkraftai" target="_blank" rel="noopener noreferrer">our YouTube channel!</a> We have videos for almost every feature and issue you may face.
    
    
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="2" className="rounded mb-3">
    <Accordion.Header>Can I use the generated content for commercial purposes?</Accordion.Header>
    <Accordion.Body>
    Yes, you have the rights for the generated content. You are free to use it however you want.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="3" className="rounded mb-3">
    <Accordion.Header>How long does it take to generate a 1,000-word article?</Accordion.Header>
    <Accordion.Body>
    Using Wordkraft, you can generate a 1000-word article draft in less than 1 minutes. 
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="4" className="rounded mb-3">
    <Accordion.Header>Are the generated outputs free of plagiarism?</Accordion.Header>
    <Accordion.Body>
    Yes, the outputs are free of plagiarism. Our A.I generates a unique piece of content for every output.
    </Accordion.Body>
  </Accordion.Item> 
</Accordion>
            </div>
        </div>
 
          </div>
        </div> 
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default Pricing;