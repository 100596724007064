const BlogToolsData = {
        // longFormArticle: {
        //     prompt: "200",
        //     icon: "📝",
        //     title: "Long Form Article Generator",
        //     category: "blog",
        //     caption: `Generate comprehensive, well-researched articles with custom word count and structure.`,
        //     inputs: [
        //         {
        //             inputTitle: `Article Type`,
        //             inputCaption: `Select the type of article you want to create`,
        //             type: 'select',
        //             options: [
        //                 'How-to Guide',
        //                 'Industry Analysis',
        //                 'Product Review',
        //                 'Case Study',
        //                 'Research Article',
        //                 'Opinion Piece',
        //                 'Trend Analysis',
        //                 'Comparison Article',
        //                 'Interview Article',
        //                 'Listicle',
        //                 'News Article',
        //                 'Technical Guide',
        //                 'Tutorial',
        //                 'Feature Story',
        //                 'Expert Roundup'
        //             ],
        //             inputExample: 'Industry Analysis'
        //         },
        //         {
        //             inputTitle: `Article Topic`,
        //             inputCaption: `Main subject of your article`,
        //             inputExample: `The Rise of Electric Vehicles: Impact on Global Transportation`,
        //             type: 'textarea'
        //         },
        //         {
        //             inputTitle: `Target Word Count`,
        //             inputCaption: `Desired length of article`,
        //             inputExample: `2500`,
        //             type: 'number'
        //         },
        //         {
        //             inputTitle: `Key Points`,
        //             inputCaption: `Main points to cover`,
        //             type: 'array',
        //             example: [
        //                 "Current EV market trends and statistics",
        //                 "Environmental impact and sustainability benefits",
        //                 "Charging infrastructure development",
        //                 "Battery technology advancements",
        //                 "Economic implications for automotive industry",
        //                 "Government policies and incentives",
        //                 "Consumer adoption challenges"
        //             ]
        //         },
        //         {
        //             inputTitle: `Target Audience`,
        //             inputCaption: `Who will read this article?`,
        //             inputExample: `Automotive industry professionals, environmentally conscious consumers, and policy makers interested in sustainable transportation`,
        //             type: 'textarea'
        //         },
        //         {
        //             inputTitle: `Include Sections`,
        //             type: 'multiselect',
        //             options: [
        //                 'Table of Contents',
        //                 'Executive Summary',
        //                 'Introduction',
        //                 'Methodology',
        //                 'Analysis',
        //                 'Case Studies',
        //                 'Expert Quotes',
        //                 'Statistics',
        //                 'Conclusion',
        //                 'References'
        //             ],
        //             example: [
        //                 'Table of Contents',
        //                 'Executive Summary',
        //                 'Introduction',
        //                 'Analysis',
        //                 'Case Studies',
        //                 'Statistics',
        //                 'Conclusion'
        //             ]
        //         },
        //         {
        //             inputTitle: `SEO Keywords`,
        //             inputCaption: `Target keywords to include`,
        //             type: 'array',
        //             example: [
        //                 "electric vehicles",
        //                 "EV adoption",
        //                 "sustainable transportation",
        //                 "electric car market",
        //                 "EV charging infrastructure",
        //                 "battery technology",
        //                 "green mobility"
        //             ],
        //             required: 'false'
        //         },
        //         {
        //             inputTitle: `Additional Notes`,
        //             inputCaption: `Any specific requirements or preferences`,
        //             inputExample: `Include recent market data from 2023-2024, focus on major EV manufacturers, highlight emerging markets in Asia and Europe, and discuss autonomous driving integration`,
        //             type: 'textarea',
        //             required: 'false'
        //         }
        //     ]
        // },
    blogTopicIdeas: {
        prompt: "201",
        icon: "💡",
        title: "Blog Topic Ideas",
        category: "blog",
        caption: "Utilize this tool to generate a range of SEO-friendly keywords tailored to your blog post's main title.",
        inputs: [{ inputTitle: `What is the focus or niche of your blog?`, inputCaption: `Specify your blog's main theme or niche (e.g., Travel, Health, Technology)`, inputExample: 'Personal finance' }]
    },
    blogTitleGenerator: {
        prompt: "205",
        icon: "🔖",
        title: "Blog Title Generator",
        category: "blog",
        caption: "This AI-powered tool acts as a creative blog title composer, offering 10 enticing and share-worthy titles in the language of your choice.",
        inputs: [{ inputTitle: `Enter your Focus Keyword`, inputCaption: `Input the main keyword or phrase your blog post is centered around`, inputExample: 'sustainable living tips' }]
    },
    blogOutline: {
        prompt: "202",
        icon: "📝",
        title: "Blog Outline Generator",
        category: "blog",
        caption: "Streamline your blog writing with the Outline Architect, assisting in crafting comprehensive outlines, captivating headlines, summaries, and relevant FAQs for your long articles.",
        inputs: [{ inputTitle: `Enter your blog topic.`, inputCaption: `Type the main topic or subject of your blog post`, inputExample: 'The Future of Remote Work' }]
    },
    blogIntroductionGenerator: {
        prompt: "212",
        icon: "📜",
        title: "Blog Introduction Generator",
        category: "blog",
        caption: "Craft an engaging introduction for your blog post.",
        inputs: [
            { inputTitle: 'Blog Topic', inputCaption: 'Enter the main topic of your blog post.', inputExample: 'Benefits of Mediterranean Diet', type: 'text' },
            { inputTitle: 'Target Audience', inputCaption: 'Describe your target audience.', inputExample: 'health-conscious individuals looking to improve their diet', type: 'text' },
            { inputTitle: 'Key Points', inputCaption: 'List the key points or questions your blog will address.', inputExample: 'key ingredients, health benefits, easy recipes', type: 'textarea' },
        ]
    },
    blogSectionWriting: {
        prompt: "213",
        icon: "🖋️",
        title: "Blog Section Generator",
        category: "blog",
        caption: "Compose detailed and engaging sections for your blog post.",
        inputs: [
            { inputTitle: 'Section Title', inputCaption: 'Enter the title for this section of your blog post.', inputExample: 'Understanding the Basics of Blockchain', type: 'text' },
            { inputTitle: 'Main Ideas', inputCaption: 'Describe the main ideas to cover in this section.', inputExample: 'definition, how it works, applications', type: 'textarea' },
            { inputTitle: 'Target Audience', inputCaption: 'Who is this section aimed at?', inputExample: 'tech enthusiasts and beginners in cryptocurrency', type: 'text' },
            { inputTitle: 'Additional Notes', inputCaption: 'Include any additional notes or specific points to address.', inputExample: 'Include comparison with traditional banking systems', type: 'textarea', required: 'false' }
        ]
    },
    blogConclusionGenerator: {
        prompt: "214",
        icon: "🔚",
        title: "Blog Conclusion Generator",
        category: "blog",
        caption: "Generate a powerful conclusion for your blog post.",
        inputs: [
            { inputTitle: 'Blog Topic', inputCaption: 'Enter the main topic of your blog post.', inputExample: 'The Importance of Mental Health Awareness', type: 'text' },
            { inputTitle: 'Key Takeaways', inputCaption: 'Summarize the key takeaways or conclusions of your blog.', inputExample: 'ways to support mental health, importance of talking about it, resources for help', type: 'textarea' },
            { inputTitle: 'Call to Action', inputCaption: 'Specify any call to action for your readers (e.g., subscribe, comment, contact).', inputExample: 'Share your story in the comments', type: 'text', required: 'false' },
            { inputTitle: 'Closing Tone', inputCaption: 'Describe the tone you want to end with (e.g., inspirational, reflective, urgent).', inputExample: 'Hopeful and empowering', type: 'text' }
        ]
    },
    blogContentCalendar: {
        prompt: "206",
        icon: "📅",
        title: "Blog Content Calendar",
        category: "blog",
        caption: "Design an effective content calendar for your blog, focusing on transactional search terms to enhance your digital footprint.",
        inputs: [{ inputTitle: `Enter your target keyword`, inputCaption: `Type the keyword you're targeting for your content calendar`, inputExample: 'yoga for beginners' }]
    },
    rewriteArticleWithKeywords: {
        prompt: "207",
        icon: "🔄",
        title: "Rewrite Article with Keywords",
        category: "blog",
        caption: "Transform your articles into unique, SEO-enhanced versions with this rewriting tool, maintaining originality and keyword optimization.",
        inputs: [
            { inputTitle: `Enter your Existing Article`, inputCaption: `Paste the article you want to rewrite`, inputExample: 'An overview of the latest digital marketing strategies', type: "textarea" },
            { inputTitle: `Enter your Focus Keyword`, inputCaption: `Type the main keyword for the article rewrite`, inputExample: 'digital marketing trends' },
            { inputTitle: `Enter your second Focus Keyword`, inputCaption: `Add a secondary keyword for enhanced SEO`, inputExample: '2024 strategies' }
        ]
    },
    advancedBlogPost: {
        prompt: "208",
        icon: "🌟",
        title: "Quick Article writer",
        category: "blog",
        caption: "This tool assists in crafting rich, appealing blog posts, ensuring uniqueness, SEO alignment, and relevance to your target audience.",
        inputs: [
            { inputTitle: `What is your blog title?`, inputCaption: `Provide the title for your blog post`, inputExample: 'Maximizing Your Home Office for Productivity' },
            { inputTitle: `Who is your target audience?`, inputCaption: `Describe your target audience`, inputExample: 'remote workers and home-based entrepreneurs' },
            { inputTitle: `Enter your Focus Keyword(s)`, inputCaption: `List the main keywords or phrases for your blog post`, inputExample: 'home office setup, productivity tips' },
            { inputTitle: `Word Count`, inputCaption: `Specify the desired word count for your post`, inputExample: '1500' }
        ]
    },
    seoOptimizedBlogPost: {
        prompt: "209",
        icon: "🔍",
        title: "SEO-Optimized Blog Post",
        category: "blog",
        caption: "Create SEO-friendly, engaging, and reader-centric blog posts using your provided title and selected keywords with this tool.",
        inputs: [
            { inputTitle: `Enter your Focus Keyword`, inputCaption: `Type the primary keyword for SEO optimization`, inputExample: 'vegan skincare products' },
            { inputTitle: `What are the keywords you want to incorporate into your blog post?`, inputCaption: `List additional keywords to include in your blog post`, inputExample: 'cruelty-free beauty, organic skincare', type: "textarea", required: 'false' }
        ]
    },
    humanWrittenBlogPost: {
        prompt: "210",
        icon: "👤",
        title: "Human Written Blog Post",
        category: "blog",
        caption: "Generate a new, original blog post free from plagiarism and AI detection. If AI content is detected, try regenerating or use the 'Rewrite Article (Extreme AI Bypass)' for better results.",
        inputs: [{ inputTitle: `What is your blog title?`, inputCaption: `Enter the title of your blog post`, inputExample: 'Exploring the World of Indie Gaming' }]
    }
}
export default BlogToolsData;
