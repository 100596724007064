import React, { useState, useEffect } from 'react';  // Added useEffect
import { Offcanvas, Tabs, Tab, Form, InputGroup } from 'react-bootstrap';
import { Bot, Search } from 'lucide-react';
import { AVAILABLE_MODELS, getProviderIcon } from './ModelSelector';

const CombinedModelAgentSelector = ({
  show,
  onHide,
  onModelSelect,
  onAgentSelect,
  selectedModel,
  selectedAgent,  // We'll use this to determine initial active tab
  allAgents = [],
  favoriteAgents = []
}) => {
  // Set initial active tab based on whether an agent is selected
  const [activeTab, setActiveTab] = useState('models');
  const [searchQuery, setSearchQuery] = useState('');

  // Update active tab when selectedAgent changes
  useEffect(() => {
    if (show && selectedAgent) {
      setActiveTab('agents');
    }
  }, [show, selectedAgent]);

  // Rest of the component remains the same
  const filteredAgents = {
    favorites: favoriteAgents.filter(agent =>
      agent?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      agent?.description?.toLowerCase().includes(searchQuery.toLowerCase())
    ),
    others: allAgents.filter(agent =>
      !favoriteAgents.find(fav => fav.id === agent.id) &&
      (agent?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
       agent?.description?.toLowerCase().includes(searchQuery.toLowerCase()))
    )
  };

  const handleModelSelect = (provider, model) => {
    onModelSelect(model.name);
    onHide();
  };

  const handleAgentSelect = (agent) => {
    onAgentSelect(agent);
    onHide();
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end" className="selector-drawer">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <div className="d-flex align-items-center gap-2">
            <Bot size={24} />
            <h5 className="mb-0">Select AI Model / AI Agents</h5>
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-3"
        >
          <Tab eventKey="models" title="AI Models">
            {Object.entries(AVAILABLE_MODELS).map(([provider, models]) => (
              <div key={provider} className="model-provider-section">
                <div className="provider-header">
                  <small className="text-muted text-uppercase fw-bold">{provider}</small>
                </div>
                {models.map((model) => (
                  <div
                    key={model.name}
                    className={`model-item ${selectedModel === model.name ? 'active' : ''}`}
                    onClick={() => handleModelSelect(provider, model)}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={getProviderIcon(provider)}
                        alt={`${provider} icon`}
                        className="provider-icon me-2 mr-3"
                        style={{ width: '34px', height: '34px' }}
                      />
                      <div>
                        <div className="model-name">{model.name}</div>
                        <div className="model-level">{model.level}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </Tab>
          <Tab eventKey="agents" title="AI Agents">
            <div className="search-container mb-4">
              <InputGroup>
                <Form.Control
                  placeholder="Search agents..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <InputGroup.Text>
                  <Search size={16} />
                </InputGroup.Text>
              </InputGroup>
            </div>

            {filteredAgents.favorites.length > 0 && (
              <div className="agent-section">
                <h6 className="section-title">⭐ Favorite Agents</h6>
                {filteredAgents.favorites.map(agent => (
                  <div
                    key={agent.id}
                    className={`agent-item ${selectedAgent?.id === agent.id ? 'active' : ''}`}
                    onClick={() => handleAgentSelect(agent)}
                  >
                    <Bot size={20} className="agent-icon" />
                    <div className="agent-info">
                      <div className="agent-name">{agent.name}</div>
                      <div className="agent-description">{agent.description}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="agent-section">
              <h6 className="section-title">All Agents</h6>
              {filteredAgents.others.map(agent => (
                <div
                  key={agent.id}
                  className={`agent-item ${selectedAgent?.id === agent.id ? 'active' : ''}`}
                  onClick={() => handleAgentSelect(agent)}
                >
                  <Bot size={20} className="agent-icon" />
                  <div className="agent-info">
                    <div className="agent-name">{agent.name}</div>
                    <div className="agent-description">{agent.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </Tab>
        </Tabs>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CombinedModelAgentSelector;