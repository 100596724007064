import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Badge, Alert, Spinner } from 'react-bootstrap';
import { Paperclip, Send, X, Bot, History, PlusCircle  } from 'lucide-react';
import { getProviderIcon } from './ModelSelector';
import ChatHistoryDrawer from './ChatHistoryDrawer';
import { useNavigate } from 'react-router-dom';

const ChatInput = ({ 
  onSubmit, 
  loading, 
  files, 
  onFileAdd, 
  onFileRemove,
  selectedModel,
  selectedChat,
  setShowCombinedSelector,
  user,
  setPendingMessage,
  setShowInlineLogin,
  recentChats,
  onChatSelect,
  onDeleteChat,
  totalChats,
  onViewAllClick
}) => {
  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const textareaRef = useRef();
  const fileRef = useRef();
  const navigate = useNavigate();

  const MAX_FILES = 3;
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

  const ACCEPTED_FILES = {
    'text/plain': '.txt',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'text/csv': '.csv',
    'application/pdf': '.pdf',
    'image/jpeg': '.jpg,.jpeg',
    'image/png': '.png',
    'image/gif': '.gif',
    'text/javascript': '.js,.jsx,.ts,.tsx',
    'text/x-python': '.py',
    'text/x-java': '.java',
    'text/x-c': '.c,.cpp,.h',
    'text/x-csharp': '.cs',
    'text/x-php': '.php',
    'text/html': '.html,.htm',
    'text/css': '.css,.scss,.sass',
    'text/x-ruby': '.rb',
    'text/x-go': '.go',
    'text/x-rust': '.rs',
    'text/x-swift': '.swift',
    'application/json': '.json',
    'application/xml': '.xml',
    'text/x-yaml': '.yml,.yaml',
    'text/x-markdown': '.md,.markdown',
    'text/x-sh': '.sh,.bash',
    'text/x-sql': '.sql'
  };

  const formatFileSize = (bytes) => {
    const sizeInKB = bytes / 1024;
    return `${Math.round(sizeInKB)} KB`;
  };

  const isValidFileExtension = (filename) => {
    const allExtensions = Object.values(ACCEPTED_FILES)
      .join(',')
      .split(',')
      .map(ext => ext.toLowerCase());
    
    const fileExtension = `.${filename.split('.').pop().toLowerCase()}`;
    return allExtensions.includes(fileExtension);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  const validateFiles = (newFiles) => {
    if (files.length + newFiles.length > MAX_FILES) {
      setError(`You can only upload up to ${MAX_FILES} files at a time`);
      return false;
    }

    for (const file of newFiles) {
      if (file.size > MAX_FILE_SIZE) {
        setError(`File "${file.name}" exceeds the 5MB size limit`);
        return false;
      }

      const isValidType = Object.keys(ACCEPTED_FILES).some(type => file.type === type) ||
                         isValidFileExtension(file.name);
                         
      if (!isValidType) {
        setError(`File "${file.name}" is not a supported format. Please check the accepted file types.`);
        return false;
      }
    }

    return true;
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.height = '45px';
    const scrollHeight = textarea.scrollHeight;
    
    if (!textarea.value.trim()) {
      textarea.style.height = '45px';
      return;
    }
    
    const maxHeight = 24 * 5;
    const newHeight = Math.min(scrollHeight, maxHeight);
    textarea.style.height = `${newHeight}px`;
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInput(newValue);
    window.requestAnimationFrame(() => {
      adjustHeight();
    });
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      adjustHeight();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() || files.length > 0) {
      if (!user) {
        setPendingMessage(input);
        setShowInlineLogin(true);
        return;
      }
      onSubmit(input);
      setInput('');
      if (textareaRef.current) {
        textareaRef.current.style.height = '45px';
        textareaRef.current.focus();
      }
    }
  };

  const handleFileAdd = (newFiles) => {
    setError('');
    if (validateFiles(newFiles)) {
      onFileAdd(newFiles);
    }
  };

  const handleFileRemove = (index) => {
    setError('');
    onFileRemove(index);
  };

  const acceptedFileTypes = Object.values(ACCEPTED_FILES).join(',');

  return (
    <div className="chat-input">
      <Form onSubmit={handleSubmit} className="chat-input-wrapper">
        {error && (
          <Alert 
            variant="danger" 
            onClose={() => setError('')} 
            dismissible
            className="chat-input-alert"
          >
            {error}
          </Alert>
        )}
        {files.length > 0 && (
          <div className="file-previews">
            {files.map((file, i) => (
              <Badge key={i} className="file-badge" variant="outline-secondary">
                {file.name} ({formatFileSize(file.size)})
                <X size={14} onClick={() => handleFileRemove(i)} className="remove-file" />
              </Badge>
            ))}
          </div>
        )}
        <div className="input-group input-form-wrapper">
          <Form.Control
            as="textarea"
            className="chat-textarea"
            ref={textareaRef}
            value={input}
            onChange={handleInputChange}
            onKeyUp={handleKeyUp}
            onInput={adjustHeight}
            onCut={adjustHeight}
            onPaste={adjustHeight}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
            placeholder="Type a message..."
            disabled={loading}
            style={{
              resize: 'none',
              overflow: 'hidden',
              height: '45px',
              maxHeight: '120px',
              padding: '12px 16px 12px 0px',
              lineHeight: '20px',
              overflowY: 'auto',
              transition: 'none' 
            }}
          />
          <div className="input-controlls">
            <input
              type="file"
              ref={fileRef}
              onChange={e => handleFileAdd(Array.from(e.target.files))}
              multiple
              hidden
              accept={acceptedFileTypes}
            />
            <Button 
              variant="outline-secondary"
              className="chat-attachment"
              onClick={() => fileRef.current?.click()}
              disabled={loading || files.length >= MAX_FILES}
              title={files.length >= MAX_FILES ? 'Maximum files limit reached' : 'Attach files'}
            >
              <Paperclip size={20} />
            </Button>

            <Button
              variant="primary-outline"
              className="combined-selector-btn d-flex align-items-center gap-2"
              onClick={() => setShowCombinedSelector(true)}
            > 
              {selectedChat?.metadata?.agent ? (
                <>
                  <Bot size={16} />
                  <span className="model-name">{selectedChat.metadata.agent.name}</span>
                </>
              ) : selectedModel ? (
                <>
                  <img
                    src={getProviderIcon(selectedModel.split('-')[0])}
                    alt="Model icon"
                    className="model-icon"
                    style={{ width: '20px', height: '20px' }}
                  />
                  <span className="model-name">{selectedModel}</span>
                </>
              ) : (
                'Select AI Model / Agent'
              )}
            </Button>

            <Button
              variant="primary-outline"
              className="new-chat-btn d-flex align-items-center gap-2"
              onClick={() => navigate('/chat')}
            >
              <PlusCircle size={16} /> New
            </Button>

            <Button
              variant="primary-outline"
              className="history-btn d-flex align-items-center gap-2"
              onClick={() => setShowHistory(true)}
            >
              <History size={16} /> History
            </Button>

            <Button 
              variant="primary"
              type="submit" 
              className="chat-submit"
              disabled={loading || (!input.trim() && !files.length)}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <Send size={20} />
              )}
            </Button>
          </div>
        </div>
      </Form>

      <ChatHistoryDrawer
        show={showHistory}
        onHide={() => setShowHistory(false)}
        recentChats={recentChats}
        selectedChat={selectedChat}
        onChatSelect={onChatSelect}
        onDeleteChat={onDeleteChat}
        totalChats={totalChats}
        onViewAllClick={onViewAllClick}
      />
    </div>
  );
};

export default ChatInput;