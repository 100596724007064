import React, { useState, useEffect, useContext } from 'react';
import { Container, Form, Button, Alert, Modal } from 'react-bootstrap';
import { Plus, ArrowLeft, Bot } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from "../../../context/UserAuthContext";
import { doc, updateDoc, collection, addDoc, serverTimestamp, getDoc } from 'firebase/firestore';  
import { ref, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../../firebase'; 
import AgentCard from './AgentCard';
import CreateAgentForm from './CreateAgentForm';
import AgentFirebaseService from './AgentFirebaseService'; 
import ConfirmationModal from './ConfirmationModal';
import * as chatService from '../AIChatServices';
import { SubscriberContext } from "../../../context/subscriberContext";
import InlinePricing from "../../views/InlinePricing";
import './AgentsPage.css';

const ADMIN_EMAIL = 'samson3d@gmail.com';

const AgentsPage = ({ availableModels = {} }) => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const { subscriber } = useContext(SubscriberContext);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [viewMode, setViewMode] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [agents, setAgents] = useState([]);
  const [isLoadingAgents, setIsLoadingAgents] = useState(false);
  const [error, setError] = useState('');
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [loginState, setLoginState] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);
  
  const isAdmin = user?.email === ADMIN_EMAIL;

  useEffect(() => {
    loadAgents();
  }, [user?.uid]);

  const loadAgents = async () => {
    if (!user?.uid) return;
    setIsLoadingAgents(true);
    setError('');
    
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      const fetchedAgents = await AgentFirebaseService.getAgents(user.uid);
      setAgents(fetchedAgents);
    } catch (error) {
      console.error('Failed to load agents:', error);
      if (error.message.includes('indexes are being created')) {
        setError('Database indexes are being created. Please wait a few minutes and try again.');
        setTimeout(loadAgents, 30000);
      } else {
        setError('Failed to load agents. Please refresh the page.');
      }
    } finally {
      setIsLoadingAgents(false);
    }
  };

  const checkSubscription = () => {
    const currentDate = Math.floor(Date.now() / 1000);
    if (subscriber?.totalWords >= subscriber?.wordsLimit || 
        subscriber?.premiumState === false || 
        currentDate > subscriber?.planEndDate) {
      setShowPricingModal(true);
      return false;
    }
    return true;
  };

  const handleCreateNewAgent = () => {
    if (!checkSubscription()) {
      return;
    }
    setShowCreateForm(true);
  };

  const getCategories = () => {
    const categories = new Set(agents.map(agent => agent.category));
    return ['all', 'favorite', ...Array.from(categories)];
  };

  const handleAgentAction = async (agentData, iconFile, mode) => {
    try {
      if (mode === 'edit') {
        await AgentFirebaseService.updateAgent(
          agentData.id,
          agentData,
          iconFile
        );
      } else {
        await AgentFirebaseService.createAgent(agentData, iconFile);
      }
      
      setShowCreateForm(false);
      setSelectedAgent(null);
      setViewMode(null);
      loadAgents();
      
    } catch (error) {
      throw new Error(`Failed to ${mode} agent: ${error.message}`);
    }
  };

  const handleAgentSelect = async (agentData) => {
    if (agentData.viewMode) {
      handleViewEditAgent(agentData, 'view');
    } else {
      try {
        const agentRef = doc(db, 'agents', agentData.id);
        const agentSnap = await getDoc(agentRef);
        const fullAgentData = agentSnap.data();

        let agentFiles = [];
        if (fullAgentData?.files && fullAgentData.files.length > 0) {
          agentFiles = await Promise.all(
            fullAgentData.files.map(async file => {
              if (file.url) {
                return file;
              }
              const fileRef = ref(storage, file.path || `agents/${agentData.id}/files/${file.name}`);
              const url = await getDownloadURL(fileRef);
              return {
                ...file,
                url
              };
            })
          );
        }

        const welcomeMessage = `Welcome to ${agentData.name}! I am your AI agent ${
          agentData.description ? `specialized in ${agentData.description}` : ''
        }. How can I help you today?`;

        const chatId = await chatService.createChat(
          welcomeMessage,
          user.uid, 
          agentData.baseModel
        );

        const chatRef = doc(db, 'chats', chatId);
        const chatMetadata = {
          'metadata.agent': {
            id: agentData.id,
            name: agentData.name,
            model: agentData.baseModel,
            files: agentFiles
          },
          'metadata.systemPrompt': agentData.systemPrompt,
          'metadata.isAgentChat': true,
          'metadata.agentFiles': agentFiles
        };

        await updateDoc(chatRef, chatMetadata);

        const messagesRef = collection(chatRef, 'messages');
        await addDoc(messagesRef, {
          text: welcomeMessage,
          sender: 'ai',
          timestamp: serverTimestamp(),
          metadata: {
            model: agentData.baseModel,
            provider: 'agent',
            agentFiles: agentFiles
          }
        });

        navigate(`/chat/${chatId}`);
      } catch (error) {
        console.error('Error creating chat with agent:', error);
        setError('Failed to create chat: ' + error.message);
      }
    }
  };
  
  const handleViewEditAgent = (agent, mode) => {
    setSelectedAgent(agent);
    setViewMode(mode);
    setShowCreateForm(true);
  };

  const handleFavoriteToggle = async (agentId, isFavorite, options = {}) => {
    const updatedAgents = agents.map(agent =>
      agent.id === agentId ? { ...agent, isFavorite } : agent
    );
    setAgents(updatedAgents);
  
    if (!options.skipReload) {
      loadAgents();
    }
  };

  const filteredAgents = agents.filter(agent => {
    if (selectedCategory === 'all') return true;
    if (selectedCategory === 'favorite') return agent.isFavorite;
    return agent.category === selectedCategory;
  });

  const handleDeleteClick = (agent) => {
    setAgentToDelete(agent);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (!agentToDelete?.id || !user?.uid) return;

    try {
      await AgentFirebaseService.deleteAgent(agentToDelete.id, user.uid);
      loadAgents();
      setShowDeleteModal(false);
      setAgentToDelete(null);
    } catch (error) {
      console.error('Error deleting agent:', error);
      setError('Failed to delete agent');
    }
  };

  const renderAgentList = () => {
    if (isLoadingAgents) {
      return (
        <div className="text-center py-4 agent-load">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-2">Loading agents...</p>
        </div>
      );
    }

    if (agents.length === 0) {
      return (
        <div className="text-center py-4">
          <p className="text-muted">No agents available. Create your first agent!</p>
          <Button
            variant="primary"
            className="create-agent-button d-flex align-items-center gap-2 mx-auto"
            onClick={handleCreateNewAgent}
          >
            <Plus size={16} />
            Create Agent
          </Button>
        </div>
      );
    }

    return (
      <div className="row g-3">
        {filteredAgents.map((agent) => (
          <div key={agent.id} className="col-md-6 col-lg-4">
            <AgentCard
              agent={agent}
              currentUserId={user?.uid}            
              onSelect={handleAgentSelect} 
              onEdit={(agent) => handleViewEditAgent(agent, 'edit')}
              onDelete={handleDeleteClick}
              onToggleFavorite={handleFavoriteToggle}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <Container className="main-layout templates"> 
      <div className={`agents-container`}> 
        <Container fluid className="agents-page py-4">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-block">
              <h4 className="mb-0 page-title">Agents</h4>
              <p>Create custom AI agents with specialized instructions and a variety of skills.</p>
            </div>         
          </div>
          {!showCreateForm && 
            <div className="d-flex flex-wrap justify-content-between gap-5 align-items-center mb-4">
              <Form.Select 
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="category-filter"
                style={{ width: 'auto', minWidth: '200px' }}
              >
                {getCategories().map(category => (
                  <option key={category} value={category}>
                    {category === 'all' ? 'All Categories' : 
                     category === 'favorite' ? 'Favorites' : 
                     category}
                  </option>
                ))}
              </Form.Select>

              {!showCreateForm && (
                <Button
                  variant="primary"
                  className="create-agent-button d-flex align-items-center gap-2"
                  onClick={handleCreateNewAgent}
                >
                  <Plus size={16} />
                  Create New Agent
                </Button>
              )}
            </div>
          }
          <div className='agent-container'>
            {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
            
            {showCreateForm ? (
              <div>
                <div className='create-agent-form d-flex gap-3 align-content-center justify-content-between mb-5'>        
                  <div className='grow-1 d-flex gap-3 align-content-center'>
                    <Bot size={29}/>
                    <h4 className='m-0'>
                      {viewMode === 'edit'
                        ? 'Update Agent'
                        : viewMode === 'view'
                        ? 'Agent Form'
                        : 'Create a Agent'}
                    </h4>
                  </div>
                  <Button
                    variant="link"
                    className="p-0 d-flex align-items-center gap-2"
                    onClick={() => {
                      setShowCreateForm(false);
                      setSelectedAgent(null);
                      setViewMode(null);
                    }}
                  >
                    <ArrowLeft size={20} />
                    Back
                  </Button>
                </div>
                <CreateAgentForm
                  availableModels={availableModels}
                  onSubmit={handleAgentAction}
                  onCancel={() => {
                    setShowCreateForm(false);
                    setSelectedAgent(null);
                    setViewMode(null);
                  }}
                  isAdmin={isAdmin}
                  mode={viewMode || 'create'}
                  initialData={selectedAgent}
                />
              </div>
            ) : (
              <>              
                {renderAgentList()}
              </>
            )}
          </div>
        </Container>
      </div>

      <Modal 
        show={showPricingModal} 
        onHide={() => setShowPricingModal(false)} 
        keyboard={false} 
        className={loginState ? "loginState" : "upgradeModal"}
      >
        <Modal.Body className="text-center">
          <InlinePricing />
        </Modal.Body>
      </Modal>

      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
          setAgentToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
        title="Delete Agent"
        message={`Are you sure you want to delete "${agentToDelete?.name}"? This action cannot be undone.`}
        confirmButtonText="Delete Agent"
        cancelButtonText="Cancel"
        variant="danger"
      /> 
    </Container>
  );
};

export default AgentsPage;