import {React, useState, useEffect, useContext} from "react";
import {Button, Card, Container, NavLink, Row, Col, Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast'; 
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";  
import CommonDataServices from "../../../services/common.services";
import { getFunctions, httpsCallable } from "firebase/functions";  
import SidebarPanel from "../../dashboard/sidebar/SidebarPanel";  
import Modal from 'react-bootstrap/Modal';
import "./Profile.css"; 
import ProgressBar from 'react-bootstrap/ProgressBar'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Loader from "../../shared/utilities/loader/Loader";
import Loader2 from "../../shared/utilities/loader2/Loader2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {         
    title: {
      display: true,
      text: 'Daily usage',
    },
    legend: {
      position: 'top',
    },
  },
  scales: {
    x: {
      ticks: {
        maxRotation: 45,
        minRotation: 45,
        callback: function(value, index, values) {
          // Format the date to be more concise
          const date = this.getLabelForValue(value);
          if (!date) return '';
          return new Date(date).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric'
          });
        }
      }
    }
  }
};

const Profile = () => { 
  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const { user } = useUserAuth();
  const navigate = useNavigate(); 
  const functions = getFunctions();  
  const [usagePercentage, setUsagePercentage] = useState(0);
  const [subscription, setSubscription] = useState(null);   
  const [subState, setSubState] = useState(false);
  const [alertMsg, setAlertMsg] = useState();  
  const [showModal, setShowModal] = useState(false); 
  const [modalData, setModalData] = useState({});
  const [cusLoad, setCusLoad] = useState(false); 
  const [inputValue, setInputValue] = useState('');
  const [filter, setFilter] = useState('7'); // Default to 7 days

  const [offerCode, setOfferCode] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  // Add this useEffect right after your existing useEffects in Profile.js

useEffect(() => {
  const fetchAccountInfo = async () => {
    try {
      const userDoc = await CommonDataServices.getUsageData(user.uid);
      const userData = userDoc.data();
      
      if (userData) {
        // If we have user data in Firestore
        setFirstName(userData.firstName || user.displayName || '');
        setLastName(userData.lastName || '');
      } else {
        // If no user data yet, use data from auth
        setFirstName(user.displayName || '');
        setLastName('');
      }
      // Always set email from auth
      setEmail(user.email || '');
      
    } catch (error) {
      console.error('Error fetching account info:', error);
      toast.error('Failed to load account information');
    }
  };

  if (user) {
    fetchAccountInfo();
  }
}, [user]); // Only run when user changes

  const [barData, setBarData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Usage',
        barPercentage: 0.5,
        barThickness: 8,
        maxBarThickness: 8,
        minBarLength: 2,
        data: [],
        backgroundColor: 'rgb(84 42 223)',
      }
    ],
  });

  useEffect(() => { 
    getDailyUsage(filter);
  }, [filter]);

  useEffect(() => {
    const fetchUserData = async () => {
      const getUserData = await CommonDataServices.getUsageData(user.uid);  
      setSubscriber(getUserData.data());
      
      // Calculate usage percentage
      if (getUserData.data()) {
        const percentage = (getUserData.data().totalWords / getUserData.data().wordsLimit) * 100;
        setUsagePercentage(Math.min(percentage.toFixed(1), 100));
      }
    };
    fetchUserData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault(); 
    const userObj = { firstName, lastName, email };
    CommonDataServices.updateUserSubscription(user.uid, userObj);
    toast.success('Account information updated!'); 
  };  

  const getDailyUsage = async (filterDays) => {  
    const usedata = await CommonDataServices.getDailyUsageDocs(user.uid);
    let dateArry = [];
    let useageArry = []; 

    // Create a map of existing data points
    const dataMap = new Map();
    usedata.docs.forEach(doc => {
      const date = new Date(doc.data().date);
      const dateStr = date.toISOString().split('T')[0];
      dataMap.set(dateStr, doc.data().count);
    });

    // Generate array for last N days including missing dates
    const days = parseInt(filterDays);
    for (let i = days - 1; i >= 0; i--) {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() - i);
      
      const dateStr = date.toISOString().split('T')[0];
      const formattedDate = date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric'
      });
      
      dateArry.push(formattedDate);
      useageArry.push(String(dataMap.get(dateStr) || '0'));

    setBarData({
      labels: dateArry,
      datasets: [{
        label: 'Words',
        maxBarThickness: 8,
        backgroundColor: 'rgb(84 42 223)',
        data: useageArry
      }]
    }); 
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const manageBilling = async () => {
    if(subscriber && subscriber.customerId){
    // console.log(subscriber)
    setCusLoad(true)
     try {
       // Replace with your Firebase Cloud Function URL
       const functionUrl = 'https://us-central1-wordkraft-bb683.cloudfunctions.net/createCustomerPortalSession';  
        
       //const customerId = 'cus_NMJCnZoeV1k7xd';
       const customerId = subscriber.customerId;
   
       const response = await fetch(functionUrl, {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({ customerId }),
       });
   
       if (!response.ok) {
         throw new Error('Failed to create customer portal session');
       }
   
       const { url } = await response.json();
       // Redirect to the session URL received from the Cloud Function
       window.location.href = url;
       setCusLoad(false)
     } catch (error) {
       console.error('Error:', error);
       setCusLoad(false)
       toast.error('Failed to manage billing. Please try again later.') 
     }
    }
   };  

  return (
    <>   
      <div className="main-layout profile page-layout">
        <div className="header-txt text-center"> 
          <h2>Profile</h2> 
          <p>Check your Account information, Billing details and Usage stats. </p>
        </div> 
        <div className="container justify-content-center">  
          <div className="row">
            <div className="col-sm-12">
              <h5 className="pb-2">Account Information</h5>
              <Card className="mb-5"> 
                <Card.Body>
                  <div className="wrapper bg-white">
                    <div className="py-4"> 
                      <Form onSubmit={handleSubmit}>
                        <Row className="justify-content-md-center mb-3">
                          <Col md="6">
                            <Form.Group controlId="firstName">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="Enter first name"
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="lastName">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Enter last name"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                          <Col md="12">
                            <Form.Group controlId="email">
                              <Form.Label>Email ID</Form.Label>
                              <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter email"
                                readOnly
                              />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="justify-content-md-center mt-3">
                              Update
                            </Button>
                          </Col>
                        </Row> 
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              
              <h5 className="pb-2">Billing Details</h5>
              <Card className="mb-5"> 
                <Card.Body>
                  <div className="wrapper bg-white billing">
                    {subscriber && <>
                      {subscriber.planType !== "free" && 
                        <div className="py-4">  
                          <div className="subHead">
                            <h6>Active Plan</h6>
                            <p>Your current active plan details</p>
                          </div>
                          <div className="row">
                            <div className="col">
                              <p><strong>Plan:</strong><br/> {subscriber.planType}
                                {!subscriber.planType === "Life Time Plan" && <>- <i>Monthly</i></>}
                              </p>
                            </div>
                            <div className="col">
                              <p><strong>Plan status:</strong><br/> Active</p>
                            </div>
                            {subscriber.planType !== "Life Time Plan" && (
                              <>
                                <div className="col">
                                  <p><strong>Start date:</strong><br/> 
                                    {new Date(subscriber.planStartDate * 1000).toLocaleDateString('en-us',
                                      {day:'numeric', month:'short', year:'numeric'})}
                                  </p>
                                </div>
                                <div className="col">
                                  <p><strong>Amount paid:</strong><br/> {subscriber.paidAmount}</p>
                                </div>
                                <div className="col">
                                  <p><strong>Next renewal:</strong><br/> 
                                    {new Date(subscriber.planEndDate * 1000).toLocaleDateString('en-us',
                                      {day:'numeric', month:'short', year:'numeric'})}
                                  </p>
                                </div>
                              </>
                            )}
                            {subscriber.planType === "Life Time Plan" && 
                              <div className="col">
                                <p><strong>Words Limit:</strong><br/> {subscriber.wordsLimit} words pm</p>
                              </div>
                            }
                          </div>
                          <div className="row">
                            <div className="col py-4">
                              {subscriber.planType === "Life Time Plan" &&
                                <div className="float-right">
                                  <Button variant="primary mt-3 mb-3 float-right px-3" size="sm" 
                                    onClick={() => navigate(`/pricing`)}>
                                    Upgrade
                                  </Button>
                                </div>
                              }
                              {subscriber.customerId && 
                                <>
                                  <Button variant="outline-primary" size="sm" onClick={manageBilling}>
                                    Update Billing Details
                                  </Button>
                                  <p className="mt-3 mb-0">
                                    Current Plan, Payment Method, Billing Information, Invoice History
                                  </p>
                                </>
                              }
                              {cusLoad && <Loader2/>} 
                              <div className="float-right loaderStyle"> 
                                {subState && <Loader />}
                              </div>    
                            </div>
                          </div>
                        </div>
                      }
                      <div className="py-4">  
                        <div className="subHead">
                          <h6 className="text-capitalize"> 
                            {subscriber.planType} {subscriber.planType === "free" && <>trial</>} usage
                          </h6>
                          <p>You are currently on {subscriber.planType} {subscriber.planType === "free" && <>trial</>}. </p>
                        </div> 
                        {(subscriber.planType === "free" || subscriber.totalWords > subscriber.wordsLimit) &&
                          <div className="float-right">
                            <Button variant="primary mt-3 float-right px-3" size="sm" 
                              onClick={() => navigate(`/pricing`)}>
                              Upgrade
                            </Button>
                          </div> 
                        }
                        {subscriber && (subscriber.wordsLimit < 380000) &&
                          <div className="row">
                            <div className="col">
                              <p className="mb-2">Check the available words on your plan.</p>
                              <ProgressBar now={usagePercentage} label={`${usagePercentage}%`} /> 
                              <p className="text-right m-0">
                                {subscriber.totalWords} / {subscriber.wordsLimit} Words
                              </p> 
                            </div> 
                          </div>
                        }
                      </div>
                    </>}
                  </div>
                </Card.Body>
              </Card>

              <h5 className="pb-2">Usage Stats</h5>
              <Card className="mb-5"> 
                <Card.Body>
                  <div className="wrapper bg-white">
                    <div className="py-4">  
                      <Row className="mb-4">
                        <Col md={4}>
                          <Form.Group controlId="filter">
                            <Form.Label>Filter by:</Form.Label>
                            <Form.Select 
                              value={filter}
                              onChange={handleFilterChange}
                              className="form-control"
                            >
                              <option value="7">Last 7 Days</option>
                              <option value="30">Last 30 Days</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <div style={{ height: '400px' }}> 
                        {barData && <Bar options={options} data={barData} />}  
                      </div> 
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div> 
      </div> 
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default Profile;