const EmailToolsData = {  
    salesEmailV1: {
        prompt:"501",
        icon: "📧",
        title:"Sales Email v1",
        category:"email",
        caption:`Generate sales emails that connect with your audience and significantly increase your conversion rates.`,
        inputs :[
            {inputTitle: `What are you selling or promoting?`, inputCaption:`Include key features of your product or service`, inputExample: `Eco-friendly water bottles that keep drinks cold for 24 hours`, type:'textarea'},
            {inputTitle: `Who is your Ideal Customer?`, inputCaption:`describe their interests`, inputExample: `Environmentally conscious consumers who value sustainability`, type:'textarea'}
        ]
    }, 
    salesEmailV2: {
        prompt:"502",
        icon: "📩",
        title:"Sales Email v2",
        category:"email",
        caption:`Craft custom sales emails that reflect your brand's unique voice and cater to your specific audience.`,
        inputs :[
            {inputTitle: `What are you selling or promoting?`, inputCaption:`Include key features of your product or service`, inputExample: `Innovative fitness tracking app that offers personalized workouts`, type:'textarea'},
            {inputTitle: `Product/Brand Name`, inputCaption:``, inputExample: `FitTrack Pro`},
            {inputTitle: `Who is your Ideal Customer?`, inputCaption:`describe their interests`, inputExample: `Fitness enthusiasts looking for a customizable workout experience`, type:'textarea'}
        ]
    }, 
    promotionEmail: {
        prompt:"503",
        icon: "🎁", 
        title:"Promotion E-Mail",
        category:"email",
        caption:`Compose engaging marketing emails that effectively promote your products or services in any desired language.`,
        inputs :[
            {inputTitle: `Describe your Product / Service`, inputCaption:``, inputExample: `A line of premium organic teas sourced directly from local farms in Sri Lanka`, type:'textarea'}
        ]
    }, 
    emailSequenceGenerator: {
        prompt:"504",
        icon: "🔗",
        title:"Email Sequence Generator",
        category:"email",
        caption:`Build a series of persuasive emails that spotlight your product's features, converting leads into customers.`,
        inputs :[
            {inputTitle: `What are you selling or promoting?`, inputCaption:`Include key features of your product or service`, inputExample: `A cloud-based project management tool designed for remote teams`, type:'textarea'}
        ]
    }, 
    emailSubjectLines: {
        prompt:"505",
        icon: "🔖", 
        title:"Email Subject Lines",
        category:"email",
        caption:`Create email subject lines that capture attention, using proven copywriting techniques to engage your recipients.`,
        inputs :[
            {inputTitle: `What's the primary goal or reason you're reaching out to the recipient?`, inputCaption:``, inputExample: `Introducing a new product feature that simplifies team collaboration`},
            {inputTitle: `What type of email are you planning to send?`, inputCaption:``, inputExample: `Promotional Emails`, type:"select", options:['Cold Outreach Emails', 'Job Application Emails', 'Networking Follow-up Emails', 'Promotional Emails', 'Newsletter Emails', 'Transactional Emails (Order Confirmations; Receipts; Password Resets)', 'Welcome Emails (for new subscribers or customers)', 'Feedback and Survey Emails', 'Event Invitation Emails', 'Re-engagement or Win-back Emails', 'other']},
            {inputTitle: `Please provide the draft of the email you're sending. `, inputCaption:``, inputExample: `Dear valued customer, We're excited to introduce...`, type:'textarea', required:'false'}
        ]
    }, 
    abandonedCartEmails: {
        prompt:"506",
        icon: "🛒",
        title:"Abandoned Cart E-Mails",
        category:"email",
        caption:`Design personalized email campaigns targeting abandoned shopping carts to turn potential losses into sales.`,
        inputs :[
            {inputTitle: `Product/Brand Name`, inputCaption:``, inputExample: `Glam Cosmetics`},
            {inputTitle: `What are you selling or promoting?`, inputCaption:`Include key features of your product or service`, inputExample: `Luxury skincare products that are cruelty-free and made from natural ingredients`, type:'textarea'}
        ]
    }, 
    leadMagnetIdeas: {
        prompt:"507",
        icon: "🧲",
        title:"Lead Magnet Ideas",
        category:"email",
        caption:`Develop enticing lead magnet concepts that deliver quick wins and valuable content to your ideal clients.`,
        inputs :[
            {inputTitle: `What's Your Business About?`, inputCaption:``, inputExample: `Digital marketing services focused on SEO and content strategy`, type:'textarea'},
            {inputTitle: `Who is your Ideal Customer?`, inputCaption:`describe their interests`, inputExample: `Small business owners looking to increase their online presence`, type:'textarea'}
        ]
    }, 
    newsletterEmail: {
        prompt:"508",
        icon: "📰", 
        title:"Newsletter E-Mail",
        category:"email",
        caption:`Efficiently create newsletters that keep your audience informed and engaged, tailored to your business's updates and topics.`,
        inputs :[
            {inputTitle: `Product/Brand Name`, inputCaption:``, inputExample: `EcoWear - Sustainable Fashion`},
            {inputTitle: `What's Your Business About?`, inputCaption:``, inputExample: `Promoting sustainable fashion and eco-friendly clothing lines`, type:'textarea'},
            {inputTitle: `Specify the theme (or the main topic )for this newsletter.`, inputCaption:``, inputExample: `Our Journey Towards Zero Waste in Fashion`, type:'textarea'},
            {inputTitle: `What’s the 1st update or topic you want to cover in the newsletter?`, inputCaption:``, inputExample: `Introduction of our new biodegradable clothing line`, type:'textarea'},
            {inputTitle: `What’s the 2nd update or topic you want to feature in the newsletter?`, inputCaption:``, inputExample: `Behind the scenes: The making of eco-friendly fabrics`, type:'textarea'},
            {inputTitle: `What’s the 3rd update or topic you want to feature in the newsletter?`, inputCaption:``, inputExample: `Upcoming events: Join us at the Sustainable Fashion Week`, type:'textarea'}
        ]
    }, 
    coldEmail: {
        prompt:"509",
        icon: "❄️",
        title:"Cold Email",
        category:"email",
        caption:`Guide your cold email creation with a focus on an enticing subject line, structured content, and a clear call to action.`,
        inputs :[
            {inputTitle: `What's the primary goal or reason you're reaching out to the recipient?`, inputCaption:``, inputExample: `To introduce our new project management software`},
            {inputTitle: `Provide the full details and highlights of the person you're reaching out to?`, inputCaption:``, inputExample: `Jane Doe, CEO of Tech Innovations - known for her commitment to enhancing productivity in the workplace`, type:'textarea'},
            {inputTitle: `Describe yourself?`, inputCaption:`Include Name, Company or Organization, role, etc...`, inputExample: `John Smith, Co-founder of NextGen Software`, type:'textarea'},    
            {inputTitle: `What unique opportunity, benefit, or solution are you offering to the recipient?`, inputCaption:``, inputExample: `Exclusive early access to our software with a special discount for your team`},
            {inputTitle: `After reading your email, what specific action would you like the recipient to take?`, inputCaption:``, inputExample: `Reply to this email to schedule a free demo`}
        ]
    }, 
    followUpColdEmail: {
        prompt:"510",
        icon: "🔂", 
        title:"Follow-up Cold Email",
        category:"email",
        caption:`This tool aids in formulating a follow-up cold email with a compelling subject, concise message, and strong call to action.`,
        inputs :[
            {inputTitle: `Please share the original cold email you sent.`, inputCaption:``, inputExample: `Dear Jane, Last week I reached out to introduce NextGen Software...`, type:'textarea'},
            {inputTitle: `Did the recipient reply? If yes, please share their response.`, inputCaption:``, inputExample: `No response received`, type:'textarea'},
            {inputTitle: `Any new updates or offers for the follow-up?`, inputCaption:``, inputExample: `Adding a new feature to our software that directly addresses your company's needs`, type:'textarea'}
        ]
    },  


    /****************************************** */     
    welcomeSeriesGenerator: {
        prompt: "511",
        icon: "👋",
        title: "Welcome Series Generator",
        category: "email",
        caption: `Create an engaging welcome email series that introduces new subscribers to your brand and builds lasting relationships.`,
        inputs: [
            {inputTitle: `Product/Brand Name`, inputCaption: ``, inputExample: `MindfulLife Meditation App`},
            {inputTitle: `What's Your Business About?`, inputCaption: ``, inputExample: `A meditation and mindfulness app featuring guided sessions and sleep stories`, type: 'textarea'},
            {inputTitle: `What's your main value proposition?`, inputCaption: ``, inputExample: `Help users reduce stress and improve sleep quality through daily meditation practice`, type: 'textarea'}
        ]
    },
    reengagementEmail: {
        prompt: "512",
        icon: "🔄",
        title: "Re-engagement Email",
        category: "email",
        caption: `Win back inactive subscribers with personalized re-engagement emails that reignite their interest in your brand.`,
        inputs: [
            {inputTitle: `Product/Brand Name`, inputCaption: ``, inputExample: `FitnessPal Plus`},
            {inputTitle: `How long has the subscriber been inactive?`, inputCaption: ``, inputExample: `3 months`},
            {inputTitle: `What special offer can you provide?`, inputCaption: ``, inputExample: `50% off next 3 months subscription`, type: 'textarea'}
        ]
    },
    productLaunchEmail: {
        prompt: "513",
        icon: "🚀",
        title: "Product Launch Email",
        category: "email",
        caption: `Build excitement and drive sales with compelling product launch emails that showcase your new offering.`,
        inputs: [
            {inputTitle: `New Product Name`, inputCaption: ``, inputExample: `UltraBook Pro X15`},
            {inputTitle: `Product Description`, inputCaption: `Include key features and benefits`, inputExample: `Premium laptop with 24-hour battery life and AI-powered performance`, type: 'textarea'},
            {inputTitle: `Launch Date`, inputCaption: ``, inputExample: `March 15, 2024`},
            {inputTitle: `Special Launch Offer`, inputCaption: ``, inputExample: `Early bird pricing with free premium accessories`, type: 'textarea'}
        ]
    },
    eventInvitationEmail: {
        prompt: "514",
        icon: "📅",
        title: "Event Invitation Email",
        category: "email",
        caption: `Create compelling event invitations that drive attendance and engagement for your virtual or in-person events.`,
        inputs: [
            {inputTitle: `Event Name`, inputCaption: ``, inputExample: `Digital Marketing Summit 2024`},
            {inputTitle: `Event Description`, inputCaption: `Include key highlights and benefits of attending`, inputExample: `Two-day virtual conference featuring industry experts and networking opportunities`, type: 'textarea'},
            {inputTitle: `Event Date and Time`, inputCaption: ``, inputExample: `April 20-21, 2024, 9:00 AM - 5:00 PM EST`},
            {inputTitle: `Registration Deadline`, inputCaption: ``, inputExample: `April 15, 2024`}
        ]
    },
    testimonialRequestEmail: {
        prompt: "515",
        icon: "⭐",
        title: "Testimonial Request Email",
        category: "email",
        caption: `Encourage satisfied customers to share their experiences with perfectly timed and persuasive testimonial requests.`,
        inputs: [
            {inputTitle: `Product/Service Used`, inputCaption: ``, inputExample: `Professional Photography Session`},
            {inputTitle: `When did they use your product/service?`, inputCaption: ``, inputExample: `2 weeks ago`},
            {inputTitle: `Any specific aspects you'd like feedback on?`, inputCaption: ``, inputExample: `Photo quality, customer service, overall experience`, type: 'textarea'}
        ]
    },
    customerFeedbackEmail: {
        prompt: "516",
        icon: "📝",
        title: "Customer Feedback Email",
        category: "email",
        caption: `Generate targeted feedback requests that encourage honest customer responses and valuable insights.`,
        inputs: [
            {inputTitle: `Product/Service Name`, inputCaption: ``, inputExample: `CloudSync Pro`},
            {inputTitle: `Specific aspect for feedback`, inputCaption: ``, inputExample: `New dashboard interface`, type: 'textarea'},
            {inputTitle: `How long has the customer been using this feature/product?`, inputCaption: ``, inputExample: `1 month`}
        ]
    },
    thankYouEmail: {
        prompt: "517",
        icon: "🙏",
        title: "Thank You Email",
        category: "email",
        caption: `Create personalized thank you emails that strengthen customer relationships and encourage repeat business.`,
        inputs: [
            {inputTitle: `What are you thanking them for?`, inputCaption: ``, inputExample: `Recent purchase of annual subscription`, type: 'textarea'},
            {inputTitle: `Any special perk or bonus to include?`, inputCaption: ``, inputExample: `Exclusive access to premium templates`, type: 'textarea'}
        ]
    },
    appointmentConfirmation: {
        prompt: "518",
        icon: "📅",
        title: "Appointment Confirmation",
        category: "email",
        caption: `Generate clear appointment confirmation emails with all necessary details and follow-up instructions.`,
        inputs: [
            {inputTitle: `Type of Appointment`, inputCaption: ``, inputExample: `Dental Check-up`},
            {inputTitle: `Date and Time`, inputCaption: ``, inputExample: `March 30, 2024 at 2:00 PM EST`},
            {inputTitle: `Special Instructions`, inputCaption: ``, inputExample: `Please arrive 15 minutes early to complete paperwork`, type: 'textarea'}
        ]
    },
    milestoneEmail: {
        prompt: "519",
        icon: "🏆",
        title: "Milestone Celebration",
        category: "email",
        caption: `Craft engaging emails that celebrate customer milestones and achievements while using your product/service.`,
        inputs: [
            {inputTitle: `What milestone are you celebrating?`, inputCaption: ``, inputExample: `Customer's 1-year anniversary`, type: 'textarea'},
            {inputTitle: `Key achievements/stats to highlight`, inputCaption: ``, inputExample: `Completed 100 projects, saved 500 hours`, type: 'textarea'},
            {inputTitle: `Special reward or offer`, inputCaption: ``, inputExample: `Free month of premium features`, type: 'textarea'}
        ]
    },
    referralProgramEmail: {
        prompt: "520",
        icon: "🤝",
        title: "Referral Program Email",
        category: "email",
        caption: `Design compelling referral program emails that incentivize existing customers to recommend your product/service.`,
        inputs: [
            {inputTitle: `Referral Reward`, inputCaption: `What do referrers get?`, inputExample: `$50 credit for each successful referral`, type: 'textarea'},
            {inputTitle: `New Customer Offer`, inputCaption: `What do referred friends get?`, inputExample: `25% off first purchase`, type: 'textarea'},
            {inputTitle: `Program Terms`, inputCaption: ``, inputExample: `Credit applied after referred friend's first purchase`, type: 'textarea'}
        ]
    },
    seasonalPromotionEmail: {
        prompt: "521",
        icon: "🎄",
        title: "Seasonal Promotion",
        category: "email",
        caption: `Create timely seasonal promotional emails that capitalize on holidays and special occasions.`,
        inputs: [
            {inputTitle: `Season/Holiday`, inputCaption: ``, inputExample: `Black Friday`},
            {inputTitle: `Promotion Details`, inputCaption: ``, inputExample: `Up to 70% off on all products`, type: 'textarea'},
            {inputTitle: `Promotion Duration`, inputCaption: ``, inputExample: `November 24-27, 2024`}
        ]
    },
    productUpdateEmail: {
        prompt: "522",
        icon: "🔄",
        title: "Product Update Email",
        category: "email",
        caption: `Announce product updates and new features in a way that highlights value and encourages adoption.`,
        inputs: [
            {inputTitle: `Update Type`, inputCaption: ``, inputExample: `New Feature Launch`, type: 'select', options: ['New Feature Launch', 'Bug Fix', 'Performance Improvement', 'Security Update', 'UI/UX Changes', 'other']},
            {inputTitle: `What's New?`, inputCaption: `Describe the updates`, inputExample: `Integrated AI-powered content suggestions`, type: 'textarea'},
            {inputTitle: `User Benefits`, inputCaption: ``, inputExample: `Save 3+ hours per week on content creation`, type: 'textarea'}
        ]
    },
    courseWelcomeEmail: {
        prompt: "523",
        icon: "📚",
        title: "Course Welcome Email",
        category: "email",
        caption: `Create welcoming course introduction emails that set expectations and excite students about their learning journey.`,
        inputs: [
            {inputTitle: `Course Name`, inputCaption: ``, inputExample: `Advanced Digital Marketing Masterclass`},
            {inputTitle: `Course Duration`, inputCaption: ``, inputExample: `6 weeks`},
            {inputTitle: `Key Learning Outcomes`, inputCaption: ``, inputExample: `Master SEO, PPC, and content marketing strategies`, type: 'textarea'},
            {inputTitle: `First Assignment/Action`, inputCaption: ``, inputExample: `Complete student survey and join Discord community`, type: 'textarea'}
        ]
    },
    serviceUpgradeEmail: {
        prompt: "524",
        icon: "⬆️",
        title: "Service Upgrade Email",
        category: "email",
        caption: `Persuade users to upgrade their service plan by highlighting premium features and exclusive benefits.`,
        inputs: [
            {inputTitle: `Current Plan`, inputCaption: ``, inputExample: `Basic Plan ($9/month)`},
            {inputTitle: `Upgrade Option`, inputCaption: ``, inputExample: `Pro Plan ($29/month)`},
            {inputTitle: `Key Premium Features`, inputCaption: ``, inputExample: `Unlimited storage, priority support, advanced analytics`, type: 'textarea'},
            {inputTitle: `Special Upgrade Offer`, inputCaption: ``, inputExample: `50% off first 3 months of Pro Plan`, type: 'textarea'}
        ]
    },
    webinarFollowupEmail: {
        prompt: "525",
        icon: "🎥",
        title: "Webinar Follow-up",
        category: "email",
        caption: `Send engaging follow-up emails after webinars to maintain momentum and drive conversions.`,
        inputs: [
            {inputTitle: `Webinar Title`, inputCaption: ``, inputExample: `Mastering Remote Team Management`},
            {inputTitle: `Key Takeaways`, inputCaption: ``, inputExample: `Communication strategies, productivity tools, team building exercises`, type: 'textarea'},
            {inputTitle: `Next Steps/Offer`, inputCaption: ``, inputExample: `Book a free consultation call`, type: 'textarea'},
            {inputTitle: `Resources Promised`, inputCaption: ``, inputExample: `Presentation slides, workflow templates, tool recommendations`, type: 'textarea'}
        ]
    },
    freemiumConversionEmail: {
        prompt: "526",
        icon: "💎",
        title: "Freemium Conversion",
        category: "email",
        caption: `Convert free users to paying customers by highlighting premium value and addressing usage patterns.`,
        inputs: [
            {inputTitle: `Current Usage Stats`, inputCaption: ``, inputExample: `Used 90% of free storage limit`, type: 'textarea'},
            {inputTitle: `Premium Features to Highlight`, inputCaption: ``, inputExample: `Unlimited storage, advanced automation, team collaboration`, type: 'textarea'},
            {inputTitle: `Conversion Offer`, inputCaption: ``, inputExample: `30% off annual premium plan`, type: 'textarea'}
        ]
    },
    surveyInvitationEmail: {
        prompt: "527",
        icon: "📊",
        title: "Survey Invitation",
        category: "email",
        caption: `Create compelling survey invitation emails that maximize response rates and gather valuable feedback.`,
        inputs: [
            {inputTitle: `Survey Purpose`, inputCaption: ``, inputExample: `Product development feedback`, type: 'textarea'},
            {inputTitle: `Estimated Time`, inputCaption: ``, inputExample: `5 minutes`},
            {inputTitle: `Incentive`, inputCaption: ``, inputExample: `$10 gift card for completed responses`, type: 'textarea'}
        ]
    },
    membershipRenewalEmail: {
        prompt: "528",
        icon: "🔄",
        title: "Membership Renewal",
        category: "email",
        caption: `Generate renewal emails that highlight membership value and encourage continued subscription.`,
        inputs: [
            {inputTitle: `Membership Type`, inputCaption: ``, inputExample: `Premium Fitness Club Access`},
            {inputTitle: `Current Benefits Used`, inputCaption: ``, inputExample: `Attended 48 classes, used personal trainer 12 times`, type: 'textarea'},
            {inputTitle: `Renewal Offer`, inputCaption: ``, inputExample: `Lock in current rate + free month`, type: 'textarea'},
            {inputTitle: `Expiration Date`, inputCaption: ``, inputExample: `March 31, 2024`}
        ]
    },
    troubleshootingEmail: {
        prompt: "529",
        icon: "🔧",
        title: "Troubleshooting Guide",
        category: "email",
        caption: `Create clear, solution-focused emails that help users resolve common technical issues.`,
        inputs: [
            {inputTitle: `Issue Type`, inputCaption: ``, inputExample: `Login authentication error`},
            {inputTitle: `Affected Feature/Product`, inputCaption: ``, inputExample: `Mobile app login screen`},
            {inputTitle: `Solution Steps`, inputCaption: ``, inputExample: `1. Clear cache 2. Update app 3. Reset password`, type: 'textarea'},
            {inputTitle: `Additional Support Options`, inputCaption: ``, inputExample: `Live chat support, video tutorial link`, type: 'textarea'}
        ]
    }    
}
export default EmailToolsData;

