import React, { useState } from 'react';

function GPTtest() {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const REACT_APP_OPENAI_API_KEY = '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';
 //https://api.openai.com/v1/chat/completions
 
 const sendToChatbot = async () => {
  const newMessages = [
    { role: "system", content: "You are an assistant." },
    ...messages,
    { role: "user", content: input }
  ];
  setMessages(newMessages);
  setInput('');

  try {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${REACT_APP_OPENAI_API_KEY}`, // Ensure this is securely handled
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        model: "gpt-4-1106-preview", // The model identifier as per your curl command
        messages: newMessages
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    const reply = result.choices[0].message.content; // Getting the reply from the response
    setMessages(prevMessages => [...prevMessages, { role: "assistant", content: reply }]);
  } catch (error) {
    console.error('Error querying GPT-4:', error);
  }
};


  

  return (
    <div>
      <div>
        {messages.map((msg, index) => (
          <p key={index}>{msg.role === 'user' ? 'You' : 'Bot'}: {msg.content}</p>
        ))}
      </div>
      <input value={input} onChange={e => setInput(e.target.value)} />
      <button onClick={sendToChatbot}>Send</button>
    </div>
  );
}

export default GPTtest;
