import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { ChevronUp, ChevronDown } from 'lucide-react';

const SortableKeywordTable = ({ 
  data, 
  onSelectChange, 
  currentPage = 1, 
  itemsPerPage = 50,
  selectedKeywords = [] // Add this prop to track selected keywords
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });

  const indexOffset = (currentPage - 1) * itemsPerPage;

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortedData = () => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      let aValue, bValue;

      switch (sortConfig.key) {
        case 'keyword':
          aValue = a.keyword?.toLowerCase() || '';
          bValue = b.keyword?.toLowerCase() || '';
          break;
        case 'volume':
          aValue = parseInt(a.avg_monthly_searches) || 0;
          bValue = parseInt(b.avg_monthly_searches) || 0;
          break;
        case 'competition':
          aValue = parseFloat(a.competition_index) || 0;
          bValue = parseFloat(b.competition_index) || 0;
          break;
        case 'kd':
          const kdValues = { 'LOW': 1, 'MEDIUM': 2, 'HIGH': 3 };
          aValue = kdValues[a.competition_value?.toUpperCase()] || 0;
          bValue = kdValues[b.competition_value?.toUpperCase()] || 0;
          break;
        case 'cpc':
          aValue = parseFloat(a['High CPC']?.replace('$', '')) || 0;
          bValue = parseFloat(b['High CPC']?.replace('$', '')) || 0;
          break;
        case 'trend':
          const getTrendValue = (item) => {
            const trendData = item.monthly_search_volumes || [];
            if (trendData.length < 2) return 0;
            return ((trendData[trendData.length - 1].searches - trendData[0].searches) / trendData[0].searches) * 100;
          };
          aValue = getTrendValue(a);
          bValue = getTrendValue(b);
          break;
        default:
          return 0;
      }

      if (sortConfig.direction === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <div className="inline-block w-4 h-4 opacity-0 group-hover:opacity-50"><ChevronUp /></div>;
    }
    return (
      <div className="inline-block w-4 h-4">
        {sortConfig.direction === 'asc' ? <ChevronUp className="text-blue-500" /> : <ChevronDown className="text-blue-500" />}
      </div>
    );
  };

  return (
    <table className="related-table w-full">
      <thead>
        <tr>
          <th width="35%" className="group cursor-pointer" onClick={() => handleSort('keyword')}>
            <div><span>Keyword</span> {getSortIcon('keyword')}</div>
          </th>
          <th width="15%" className="group cursor-pointer" onClick={() => handleSort('volume')}>
            <div><span>Volume</span> {getSortIcon('volume')}</div>
          </th>
          <th width="12%" className="group cursor-pointer" onClick={() => handleSort('competition')}>
            <div><span>Competition</span> {getSortIcon('competition')}</div>
          </th>
          <th width="15%" className="group cursor-pointer" onClick={() => handleSort('kd')}>
            <div><span>KD</span> {getSortIcon('kd')}</div>
          </th>
          <th width="12%" className="group cursor-pointer" onClick={() => handleSort('cpc')}>
            <div><span>High CPC</span> {getSortIcon('cpc')}</div>
          </th>
          <th width="11%" className="group cursor-pointer" onClick={() => handleSort('trend')}>
            <div><span>Trend</span> {getSortIcon('trend')}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {getSortedData().map((item, index) => {
          const globalIndex = indexOffset + index;
          
          const trendData = item.monthly_search_volumes || [];
          const trendChange = trendData.length > 1 
            ? ((trendData[trendData.length - 1].searches - trendData[0].searches) / trendData[0].searches) * 100
            : 0;
          
          const isTrendPositive = trendChange > 0;
          const trendArrow = isTrendPositive ? '↑' : '↓';
          const trendColor = isTrendPositive ? 'text-green-500' : 'text-red-500';
          
          // Check if this keyword is in the selectedKeywords array
          const isChecked = selectedKeywords.includes(item.keyword);
          
          return (
            <tr key={`keyword-row-${globalIndex}`} className="hover:bg-gray-50">
              <td>
                <Form.Check
                  id={`related-checkbox-${globalIndex}`}
                  inline
                  label={item.keyword}
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => onSelectChange(e, item.keyword)}
                />
              </td>
              <td>
                <span>{item.avg_monthly_searches}</span>
              </td>
              <td>
                <span className={item.competition_value?.toLowerCase()}>
                  {item.competition_index}
                </span>
              </td>
              <td>
                <span className={item.competition_value?.toLowerCase()}>
                  {item.competition_value}
                </span>
              </td>
              <td>
                <span>{item['High CPC']}</span>
              </td>
              <td>
                <span>
                  <span className={`trend-data ${trendColor}`}>{trendArrow}</span> {Math.abs(trendChange).toFixed(1)}%
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SortableKeywordTable;