import {React, useState, useEffect, useContext} from "react";
import { Button, Card, Container, Col} from "react-bootstrap"; 
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";  
import { NavLink } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast'; 
import { FcPaid } from "react-icons/fc";
import "./SidebarPanel.css"; 
import { BlogIdeaIcon,NavImageIcon,NavArticleIcon,NavKeywordIcon,NavContentIcon,NavTemplatesIcon,NavToolsIcon,NavDashboardIcon, LogOutIcon, PricingIcon, ProfileIcon, SupportIcon, SideArrow, UpgradeIcon, TemplateIcon, PicIcon, TempIcon, MyContentIcon, ImageGeneratorIcon, ChatIcon, BotIcon  } from "../../../assets/svg/SvgIcons";
import UsageBadge from "../../shared/utilities/usage-badge/UsageBadge"; 
import BusinessData from "../../../services/templaetsData/BusinessData";
import ToolsData from "../../../services/toolsData/ToolsData"; 
import { useFavorites } from '../../../context/FavoritesContext';
import { useSidebar } from '../../../context/SidebarContext';
import { Bot } from 'lucide-react';

const SidebarPanel = ({ expanded, setExpanded }) => { 
  const { favorites } = useFavorites();
  const { logOut, user  } = useUserAuth(); 
  const navigate = useNavigate();
  if(user){ localStorage.setItem("user", user.uid)}  
  const [selctedItem, setSelctedItem] = useState('all')
  const [allItem, setAllItem] = useState(true) 
  const [showItem, setShowItem] = useState(true) 
  // const handleTab =(e)=> {
  //   if(e.target.title != 'all'){
  //     setAllItem(false)
  //   } else(
  //     setAllItem(true)
  //   )
  //   setSelctedItem('');
  //   setSelctedItem(e.target.title); 
  // } 
 
  const { isSidebarVisible, toggleSidebar } = useSidebar();

  const handleTab = (e) => {
    if (e.target.title !== "all") {
      setAllItem(false);
    } else {
      setAllItem(true);
      setShowItem(!showItem);
    }
    setSelctedItem("");
    setSelctedItem(e.target.title);
  };
  
  

  const [businessNavExpanded, setBusinessNavExpanded] = useState(false);
  const [toolsVisible, setToolsVisible] = useState(false);
  const [templatesVisible, setTemplatesVisible] = useState(false);
  const location = useLocation();

  const [businessNav, setBusinessNav] = useState(null);  
  const toggleBusinessNav = () => {
    setBusinessNavExpanded(!businessNavExpanded);
  };
  useEffect(() => { 
    if(BusinessData){
      setBusinessNav(BusinessData); 
    }  

    if (location.pathname.includes('/tools')) {
      setToolsVisible(true);
    } else {
      setToolsVisible(false);
    }

    if (location.pathname.includes('/templates/')) {
      setTemplatesVisible(true);
    } else {
      setTemplatesVisible(false);
    } 
  }, [location]);

  const ArrowIcon = ({ isOpen }) => (
    <span className={`arrow-icon ${isOpen ? 'up' : 'down'}`} />
  );

  const [expandedCategories, setExpandedCategories] = useState({});

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({ ...prev, [category]: !prev[category] }));
  };

  const groupByCategory = (toolsData) => {
    const allTools = Object.values(toolsData).flat();
    return allTools.reduce((acc, tool) => {
      const category = tool.category.toLowerCase();  
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push(tool);
        return acc;
    }, {});
};
const categoryEmojis = {
  "advertising": "📢",
  "blog": "📰",
  "copywriting": "✍️",
  "ecommerce": "🛒",
  "email": "📧",
  "film": "🎥",
  "hr": "👥", 
  "idea": "💡",
  "repurpose": "♻️",
  "image": "🖼️",
  "music": "🎶",
  "seo": "🔍", 
  "sales": "💰",
  "social media": "🌐",
  "support": "🛠️",
  "website": "🌍",
  "writing assistant": "📝",
  "youtube": "▶️",
  "name & title":"🏷️",
  "resume-jobs":"📄",
  "travel planning":"✈️",
  "story poetry":"📖", 
  "education": "🎓",
  "podcast": "🎙️",
  "story writing":"📜",
  "extra": "🌟",
  "chat":"💬",
};
 
const handleLogout = async () => {
  try {
    await logOut();
    navigate("/");
  } catch (error) {
    //console.log(error.message);
  }
}; 

useEffect(() => {
  if (isSidebarVisible) {
    toggleSidebar(); // Close the sidebar when the URL changes
  }
}, [location]); 

  return (
    <Col xs={12} md={3} className={`fixed-sidebar sidebar ${isSidebarVisible ? 'visible' : ''}`}>   
<div className="sidebar-nav new-tools ">
  {ToolsData && (
    <div className="sidebar-content pb-0">
        
                <nav>  
                  <ul className="mb-3">
                    <li>
                      <NavLink to="/" className="sidebar-link">
                       <span className="dahsboard-icon"> <NavDashboardIcon /></span>
                        Dashboard
                      </NavLink>
                    </li>
                  </ul>
            <div className="subHead ">Get started</div>
                  <ul>
                    <li>
                      <NavLink to="/keyword-research" className="sidebar-link">
                        <NavKeywordIcon />
                        Keyword Research
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/article-writer" className="sidebar-link">
                        <NavArticleIcon />
                        Article Writer
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/chat" className="sidebar-link"> 
                        <ChatIcon/>
                        SmartChat 
                      </NavLink>
                    </li>
                    <li className="botIcon">
                      <NavLink to="/agents" className="sidebar-link"> 
                        <BotIcon/>
                        Agents 
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/image-generator" className="sidebar-link">
                        <NavImageIcon />
                        Image Generator 
                      </NavLink>
                    </li>
                    <li>
                    <NavLink  to="/my-content" className="sidebar-link">
                      <MyContentIcon />
                      History (My content)
                    </NavLink></li>
                    {/* <li>
                      <NavLink to="/flexy-template" activeClassName="active" className="sidebar-link">
                        <NavFlexyIcon />
                        Flexy Template
                      </NavLink>
                    </li> */}
                  </ul>
                  {favorites && <>
                    {/* <pre>{ JSON.stringify(favorites, undefined, 2)}</pre>   */}
                <div className="subHead mt-4">Favorites</div>
            <ul className="mb-3">
            {favorites.map((fav, index) => fav.toolPrompt && fav.toolName && (
                <li key={index}>
                  <NavLink to={`/ai-tools/?prompt=${fav.toolPrompt}`} className="fav-link"> 
                  <span className="emoji-icon favrIcon">❤️</span> {fav.toolName}
                      </NavLink> 
                </li>
            ))}
            </ul>
              </>}
                </nav>

    <div className="subHead mt-2">AI Tools</div>
    {Object.entries(groupByCategory(ToolsData)).map(([category, tools]) => {
  // Check if any tool in this category is active
  const isCategoryActive = tools.some(tool => {
    let toolPath = `/ai-tools/?prompt=${tool.prompt}`;
    if (tool.prompt === '208') {
      toolPath = '/article-writer';
    } else if (tool.prompt === '209') {
      toolPath = '/keyword-research';
    } 
    // else if (tool.prompt === '210') {
    //   toolPath = '/blog-post';
    // }
    return `${location.pathname}${location.search}` === toolPath;
  });

  return (
    <ul key={category}>
      <li onClick={() => toggleCategory(category)} className={`sidebar-link${isCategoryActive ? ' active' : ''}`}>
        <span className="linkTitle">
          <span className="emoji-icon">{categoryEmojis[category] ? `${categoryEmojis[category]} ` : ''}</span> 
          {category} {"Tools"} 
        </span>
        <span className="count">{tools.length}</span>
        <ArrowIcon isOpen={expandedCategories[category]} />
      </li>
      {expandedCategories[category] && (
        <ul>
           {tools.map(tool => {
      // Determine the path based on tool.id
      let path = `/ai-tools/?prompt=${tool.prompt}`;
      if (tool.prompt === '208') {
        path = '/article-writer';
      } else if (tool.prompt === '209') {
        path = '/keyword-research';
      } 
      // else if (tool.prompt === '210') {
      //   path = '/blog-post';
      // }

      // Check if the current path with query string is active
      const currentUrl = `${location.pathname}${location.search}`;
      const isActive = currentUrl === path;

      return (
        <li key={tool.prompt}>
          <Link to={path} className={`sidebar-link${isActive ? ' active' : ''}`}>
            <span className="emoji-icon">{tool.icon}</span> <span>{tool.title}</span>
          </Link>
        </li>
      );
    })}
        </ul>
      )}
    </ul>
  );
})}

    </div>
  )} 
        <div className="sidebar-content">
       
            <div className="sidebar-nav mb-2">
            <nav>
           
  <ul>
    <li>
      <a  onClick={() => setToolsVisible(!toolsVisible)} className={`sidebar-link parent-link ${toolsVisible ? 'active' : ''}`}>
        <NavToolsIcon />
        <span className="linkTitle"><span>Tools</span> <span className="count">67</span></span> 
        <ArrowIcon isOpen={toolsVisible} />
      </a> 
      {toolsVisible && (
        <ul>
          <li>
            <NavLink to="/tools/blog-content" className="sidebar-link">
              <TempIcon />
              Blog Content
            </NavLink>
          </li>
          <li>
            <NavLink to="/tools/website-seo" className="sidebar-link">
              <TemplateIcon />
              Website & SEO
            </NavLink>
          </li>
          <li>
            <NavLink to="/tools/social-media" className="sidebar-link">
              <PicIcon />
              Social Media
            </NavLink>
          </li>
          <li>
            <NavLink to="/tools/marketing" className="sidebar-link">
              <UpgradeIcon />
              Marketing
            </NavLink>
          </li>
          <li>
            <NavLink to="/tools/other" className="sidebar-link">
              <UpgradeIcon />
              Misc..
            </NavLink>
          </li>
          <li>
            <Link to="/tools" className="sidebar-link">
              <UpgradeIcon />
              All Tools
            </Link>
          </li>
        </ul> 
        )}  
    </li>
    <li>
      <a  onClick={() => setTemplatesVisible(!templatesVisible)} className={`sidebar-link parent-link ${templatesVisible ? 'active' : ''}`}>
        <NavTemplatesIcon />
        <span className="linkTitle"><span>Templates</span> <span className="count">78</span></span> 
         <ArrowIcon isOpen={templatesVisible} />
      </a>  
      {templatesVisible && (
      <ul> 
          <li>
            <NavLink to="/templates/marketing" className="sidebar-link">
              <TemplateIcon />
             Marketing
            </NavLink>
          </li>
          <li>
            <NavLink to="/templates/careers" className="sidebar-link">
              <PicIcon />
              Careers
            </NavLink>
          </li>
          <li>
            <NavLink to="/templates/hr" className="sidebar-link">
              <UpgradeIcon />
              HR
            </NavLink>
          </li>
            <li>
      <NavLink to="/templates/emails" className="sidebar-link">
        <NavContentIcon />
        Emails
      </NavLink>
    </li>
    <li>
      <NavLink to="/templates/personal" className="sidebar-link">
        <NavContentIcon />
        Personal
      </NavLink>
    </li>
          <li>
            <Link to="/templates" className="sidebar-link">
             
              All Templates
            </Link>
          </li>
        </ul> 
        )}
    </li> 
  </ul>
<div className="myAccountSideBar">
  <div className="subHead mt-4">My Account</div>
  <ul>
  <li>
    <NavLink to="/profile" className="sidebar-link">
      <ProfileIcon/> My Profile
    </NavLink>
  </li>
  <li>
    <NavLink to="/pricing" className="sidebar-link">
      <PricingIcon/> Pricing Plans
    </NavLink>
  </li>
  <li>
    <a href="https://wordkraft.ai/support/" target="_blank" className="sidebar-link">
      <SupportIcon/> Support
    </a>
  </li>
  <li>
    <a onClick={handleLogout} className="sidebar-link"> 
      <LogOutIcon/> Logout
    </a>
  </li>
</ul>
</div>
            </nav>
<div className="dview mt-5"></div>
          
            </div> 
        </div>
        {/* <div className="usageSec">
            <UsageBadge/>
          </div>  */}
      </div>
    </Col>
  );
};

export default SidebarPanel;