import { db } from "../firebase"; 

import {
  collection,
  writeBatch ,
  getDocs,
  getDoc,
  doc,
  setDoc, 
  updateDoc,
  deleteField,
  Timestamp 
} from "firebase/firestore";  

class CommonDataServices {  
 
  state = {
    language: "en",
    setLanguage: this.setLanguage
  };

  setUserSubscription = (uid) => { 
    //oneMonth
    // var oneMonthDate = new Date(); 
    // oneMonthDate.setMonth(oneMonthDate.getMonth() + 1); 
    // //console.log(oneMonthDate);

    let currentDate = Math.floor(Date.now() / 1000)
    let oneMonth = new Date();
    let expDate  = Math.round(oneMonth.setMonth(oneMonth.getMonth()+1) / 1000);
    let sixMonths = Math.round(oneMonth.setMonth(oneMonth.getMonth()+6) / 1000);

    const newSub = {
      userId: uid, 
      planType: "free",
      planStartDate: currentDate,
      planEndDate: expDate,
      premiumState: true,
      paidAmount: 0,
      totalWords: 0,
      wordsLimit: 1500 
    }
    const userRef = doc(db, `userData/${uid}/`); 
    return setDoc(userRef, newSub); 
  }
  updateUserSubscription = (uid, subObj) => {    
    //console.log(subObj)
    const userRef = doc(db, `userData/${uid}/`); 
    return updateDoc(userRef, subObj); 
  }
  updateEndDate = (uid, endDate) => { 
    const userRef = doc(db, `userData/${uid}/`); 
    return updateDoc(userRef, {planEndDate: endDate})
  }

  getTotalUsageData = (uid) => {
        const projectSecRef = doc(db, `userData/${uid}`); 
        return getDoc(projectSecRef);  
   }
  getDailyUsageDocs = (uid) => {
    const collRef = collection(db, `userData/${uid}/usageData`); 
    return getDocs(collRef);  
  }
  getSubscriptionData = (uid) => {
    const docRef = doc(db, `subscription/${uid}`); 
    return getDoc(docRef); 
  } 
  getDayUsageData = (uid, dateID) => {
    //console.log(dateID) 
    const projectSecRef = doc(db, `userData/${uid}/usageData/${dateID}`); 
    return getDoc(projectSecRef);  
  }
  getUsageLimit = (uid) => {
    const projectSecRef = doc(db, `userData/${uid}`); 
    return getDoc(projectSecRef);  
  }

  usageTrackingData = async (uid, response) => {
    //console.log(response)
    let wordCount = this.sendWordCount(response);
    //console.log(wordCount)
    if(wordCount){
        //console.log(wordCount)    
        const date = new Date();
        const dateID = date.toLocaleDateString('en-GB').split('/').reverse().join('');  
        //console.log(dateID)
        const dayRef = doc(db, `userData/${uid}/usageData/${dateID}/`); 
        const totalRef = doc(db, `userData/${uid}/`); 
        let dayCount = 0;
        //get Usage count
        await this.getDayUsageData(uid, dateID).then((resp) =>{ 
          const result = resp.data();
          if(result){
            dayCount = resp.data().count;
            //console.log(result)
          } else {
            dayCount = undefined;
          }
        }); 
        const getTotalCount = await this.getTotalUsageData(uid, dateID);
        const totalCount = getTotalCount.data().totalWords;
        const fixedwordLimit = getTotalCount.data().wordsLimit
        
        if(dayCount){   
          let countUpdate = wordCount + dayCount;
          let totalUsage = wordCount + totalCount; 
                 updateDoc(totalRef, {totalWords: totalUsage, wordsLimit: fixedwordLimit}); 
          return setDoc(dayRef, {count: countUpdate, date: new Date().toDateString()});  
        } else{   
          let totalUsage = wordCount + totalCount;
                 updateDoc(totalRef, {totalWords: totalUsage, wordsLimit: fixedwordLimit}); 
          return setDoc(dayRef, {count: wordCount, date: new Date().toDateString()}); 
        } 
    }  
  } 

  usageTrackingData2 = async (uid, response) => {
    //console.log(response)
    let wordCount = this.sendWordCount2(response);
    //console.log(wordCount)
    if(wordCount){
        //console.log(wordCount)    
        const date = new Date();
        const dateID = date.toLocaleDateString('en-GB').split('/').reverse().join('');  
        //console.log(dateID)
        const dayRef = doc(db, `userData/${uid}/usageData/${dateID}/`); 
        const totalRef = doc(db, `userData/${uid}/`); 
        let dayCount = 0;
        //get Usage count
        await this.getDayUsageData(uid, dateID).then((resp) =>{ 
          const result = resp.data();
          if(result){
            dayCount = resp.data().count;
            //console.log(result)
          } else {
            dayCount = undefined;
          }
        }); 
        const getTotalCount = await this.getTotalUsageData(uid, dateID);
        const totalCount = getTotalCount.data().totalWords;
        const fixedwordLimit = getTotalCount.data().wordsLimit
        
        if(dayCount){   
          let countUpdate = wordCount + dayCount;
          let totalUsage = wordCount + totalCount; 
                 updateDoc(totalRef, {totalWords: totalUsage, wordsLimit: fixedwordLimit}); 
          return setDoc(dayRef, {count: countUpdate, date: new Date().toDateString()});  
        } else{   
          let totalUsage = wordCount + totalCount;
                 updateDoc(totalRef, {totalWords: totalUsage, wordsLimit: fixedwordLimit}); 
          return setDoc(dayRef, {count: wordCount, date: new Date().toDateString()}); 
        } 
    }  
  } 

  usageTrackingData4 = async (uid, response) => {
    let wordCount = response;
    if (wordCount) {
      const date = new Date();
      const dateID = date.toLocaleDateString('en-GB').split('/').reverse().join('');
      const dayRef = doc(db, `userData/${uid}/usageData/${dateID}/`);
      const totalRef = doc(db, `userData/${uid}/`);
      let dayCount = 0;
  
      await this.getDayUsageData(uid, dateID).then((resp) => {
        const result = resp.data();
        if (result) {
          dayCount = resp.data().count;
        } else {
          dayCount = undefined;
        }
      });
  
      const getTotalCount = await this.getTotalUsageData(uid, dateID);
      const totalCount = getTotalCount.data().totalWords;
      const fixedwordLimit = getTotalCount.data().wordsLimit;
  
      if (dayCount) {
        let countUpdate = wordCount + dayCount;
        let totalUsage = parseInt(wordCount + totalCount);
        await updateDoc(totalRef, { totalWords: totalUsage, wordsLimit: fixedwordLimit });
        return setDoc(dayRef, { count: countUpdate, date: new Date().toDateString() });
      } else {
        let totalUsage = parseInt(wordCount + totalCount);
        await updateDoc(totalRef, { totalWords: totalUsage, wordsLimit: fixedwordLimit });
        return setDoc(dayRef, { count: wordCount, date: new Date().toDateString() });
      }
    }
  };

  sendWordCount2 = (response) => { 
    let wordCount = 0; 
    let dataWords = response.data;
   // console.log(response) 
    wordCount = dataWords.trim().split(/\s+/).length;
    if(wordCount > 1){ 
     // console.log(wordCount)
      return wordCount;
    }  
 }

 
 usageTrackingData3 = async (uid, response) => {
  //console.log(response)
  let wordCount = this.sendWordCount3(response);
  //console.log(wordCount)
  if(wordCount){
      //console.log(wordCount)    
      const date = new Date();
      const dateID = date.toLocaleDateString('en-GB').split('/').reverse().join('');  
      //console.log(dateID)
      const dayRef = doc(db, `userData/${uid}/usageData/${dateID}/`); 
      const totalRef = doc(db, `userData/${uid}/`); 
      let dayCount = 0;
      //get Usage count
      await this.getDayUsageData(uid, dateID).then((resp) =>{ 
        const result = resp.data();
        if(result){
          dayCount = resp.data().count;
          //console.log(result)
        } else {
          dayCount = undefined;
        }
      }); 
      const getTotalCount = await this.getTotalUsageData(uid, dateID);
      const totalCount = getTotalCount.data().totalWords;
      const fixedwordLimit = getTotalCount.data().wordsLimit
      
      if(dayCount){   
        let countUpdate = wordCount + dayCount;
        let totalUsage = wordCount + totalCount; 
               updateDoc(totalRef, {totalWords: totalUsage, wordsLimit: fixedwordLimit}); 
        return setDoc(dayRef, {count: countUpdate, date: new Date().toDateString()});  
      } else{   
        let totalUsage = wordCount + totalCount;
               updateDoc(totalRef, {totalWords: totalUsage, wordsLimit: fixedwordLimit}); 
        return setDoc(dayRef, {count: wordCount, date: new Date().toDateString()}); 
      } 
  }  
} 

sendWordCount3 = (response) => { 
  let wordCount = 0; 
  response.forEach((doc) => {
      wordCount = wordCount + parseInt(doc.text.split(' ').filter(word => word !== '').length);   
  }) 
  if(wordCount > 1){ 
    //console.log(wordCount)
    return wordCount;
  }  
}

  imageUsageTrackingData = async (uid, imgCredits) => {
    //console.log(response)
    let wordCount = imgCredits;
    //console.log(wordCount)
    if(wordCount){
        //console.log(wordCount)    
        const date = new Date();
        const dateID = date.toLocaleDateString('en-GB').split('/').reverse().join('');  
        //console.log(dateID)
        const dayRef = doc(db, `userData/${uid}/usageData/${dateID}/`); 
        const totalRef = doc(db, `userData/${uid}/`); 
        let dayCount = 0;
        //get Usage count
        await this.getDayUsageData(uid, dateID).then((resp) =>{ 
          const result = resp.data();
          if(result){
            dayCount = resp.data().count;
            //console.log(result)
          } else {
            dayCount = undefined;
          }
        }); 
        const getTotalCount = await this.getTotalUsageData(uid, dateID);
        const totalCount = getTotalCount.data().totalWords;
        const fixedwordLimit = getTotalCount.data().wordsLimit
        
        if(dayCount){   
          let countUpdate = wordCount + dayCount;
          let totalUsage = wordCount + totalCount; 
                 updateDoc(totalRef, {totalWords: totalUsage, wordsLimit: fixedwordLimit}); 
          return setDoc(dayRef, {count: countUpdate, date: new Date().toDateString()});  
        } else{   
          let totalUsage = wordCount + totalCount;
                 updateDoc(totalRef, {totalWords: totalUsage, wordsLimit: fixedwordLimit}); 
          return setDoc(dayRef, {count: wordCount, date: new Date().toDateString()}); 
        } 
    }  
  } 


  sendWordCount = (response) => { 
    let wordCount = 0; 
    response.data.choices.forEach((doc) => {
        wordCount = wordCount + parseInt(doc.text.split(' ').filter(word => word !== '').length);   
    }) 
    if(wordCount > 1){ 
      //console.log(wordCount)
      return wordCount;
    }  
 }

 addOfferCodes = async (codes) => {
  const batch = writeBatch(db); // Use writeBatch instead of db.batch()
 
  const currentDate = new Date();
  // Add 6 months to the current date
  const sixMonthsLater = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 6,
    currentDate.getDate(),
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds(),
    currentDate.getMilliseconds()
  );
  const sixMonths = Timestamp.fromDate(sixMonthsLater);

  codes.forEach(code => {
    const docRef = doc(collection(db, 'offerCodes'), code); // Use collection() and doc() functions
    batch.set(docRef, { 
      code:code,
      isActive:true, 
      expiryDate:sixMonths
    }); // Use batch.set() from modular SDK
  });

  try {
    await batch.commit();
    console.log('Offer codes added successfully!');
  } catch (error) {
    console.error('Error adding offer codes: ', error);
  }
};

getUsageData = (uid) => {
  const docRef = doc(db, `userData/${uid}`);   
  return getDoc(docRef);  
}

updateUserData = (uid, userObj) => {    
    console.log(userObj)
    const userData = {
      firstName: userObj.firstName,
      lastName: userObj.lastName ? userObj.lastName: " ",
      email: userObj.email
    }
    const userRef = doc(db, `userData/${uid}/`); 
    return updateDoc(userRef, userData); 
}
getOfferCodeData = (cid) => {
  const docRef = doc(db, `offerCodes/${cid}`);   
  return getDoc(docRef);  
} 
updateAndDeleteUserData = async (userId, updateData, fieldsToRemove) => {
  const userDoc = doc(db, `userData/${userId}/`); 
    
  // Update properties
  await updateDoc(userDoc, updateData);

  // Remove properties
  const removeData = fieldsToRemove.reduce((acc, curr) => {
    acc[curr] = deleteField();
    return acc;
  }, {});
  await updateDoc(userDoc, removeData);
}

}

export default new CommonDataServices();

 