import React from 'react';
import { Offcanvas, Button } from 'react-bootstrap';
import { MessageCircleMore, Trash } from 'lucide-react';

const ChatHistoryDrawer = ({ 
  show, 
  onHide, 
  recentChats = [], 
  selectedChat,
  onChatSelect,
  onDeleteChat,
  totalChats,
  onViewAllClick
}) => {
  return (
    <Offcanvas show={show} onHide={onHide} placement="end" className="chat-history-drawer">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="d-flex align-items-center gap-2">
          <MessageCircleMore size={20} />
          <span>Chat History</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="chat-list-section">
          <div className="chat-list">
            {recentChats.map(chat => (
              <div
                key={chat.id}
                className={`chat-item ${selectedChat?.id === chat.id ? 'active' : ''}`}
                onClick={() => {
                  onChatSelect(chat);
                  onHide();
                }}
              >
                <span className="chat-preview">
                  {chat.metadata?.lastMessage?.text || 'New Chat'}
                </span>
                <Button
                  variant="link"
                  className="delete-chat"
                  onClick={(e) => onDeleteChat(chat.id, e)}
                >
                  <Trash size={16} />
                </Button>
              </div>
            ))}
          </div>
          {totalChats > 10 && (
            <Button
              variant="link"
              className="view-all-btn"
              onClick={() => {
                onViewAllClick();
                onHide();
              }}
            >
              View All Chats ({totalChats})
            </Button>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ChatHistoryDrawer;