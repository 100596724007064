import React, { useState, useEffect, useContext } from 'react';
import { Eye, Edit2, Trash2, Star, Bot, MessageSquarePlus, MessageCircleMore } from 'lucide-react';
import { Modal } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AgentFirebaseService from './AgentFirebaseService';
import { SubscriberContext } from "../../../context/subscriberContext";
import InlinePricing from "../../views/InlinePricing";
import './AgentCard.css';

const AgentCard = ({ 
  agent, 
  currentUserId, 
  onSelect, 
  onEdit, 
  onView, 
  onDelete, 
  onToggleFavorite 
}) => {
  const [isFavorite, setIsFavorite] = useState(agent.isFavorite || false);
  const [isTogglingFavorite, setIsTogglingFavorite] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [loginState, setLoginState] = useState(false);
  const { subscriber } = useContext(SubscriberContext);

  useEffect(() => {
    const loadFavoriteStatus = async () => {
      try {
        const status = await AgentFirebaseService.getFavoriteStatus(agent.id, currentUserId);
        setIsFavorite(status);
      } catch (error) {
        console.error('Error loading favorite status:', error);
      }
    };
    loadFavoriteStatus();
  }, [agent.id, currentUserId]);

  useEffect(() => {
    setIsFavorite(agent.isFavorite || false);
  }, [agent.isFavorite]);

  const checkSubscription = () => {
    const currentDate = Math.floor(Date.now() / 1000);
    if (subscriber?.totalWords >= subscriber?.wordsLimit || 
        subscriber?.premiumState === false || 
        currentDate > subscriber?.planEndDate) {
      setShowPricingModal(true);
      return false;
    }
    return true;
  };

  const handleStartChat = () => {
    if (!checkSubscription()) {
      return;
    }
    onSelect({ type: 'agent', ...agent });
  };

  const handleFavoriteToggle = async (e) => {
    e.stopPropagation();
    if (isTogglingFavorite) return;
    
    setIsTogglingFavorite(true);
    try {
      if (isFavorite) {
        await AgentFirebaseService.removeFromFavorites(agent.id, currentUserId);
        setIsFavorite(false);
        onToggleFavorite?.(agent.id, false, { skipReload: true });
      } else {
        await AgentFirebaseService.addToFavorites(agent.id, currentUserId);
        setIsFavorite(true);
        onToggleFavorite?.(agent.id, true, { skipReload: true });
      }
    } catch (error) {
      console.error('Error toggling favorite status:', error);
      setIsFavorite(!isFavorite);
    } finally {
      setIsTogglingFavorite(false);
    }
  };

  const canEdit = currentUserId === agent.userId || (agent.isAdmin && currentUserId === agent.creator);
  const canDelete = currentUserId === agent.userId || (agent.isAdmin && currentUserId === agent.creator);

  return (
    <div className="agent-card">
      <div className="agent-content">
        <div className="agent-icon">
          {agent.iconUrl ? (
            <img
              src={agent.iconUrl}
              alt={agent.name}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          ) : (
            <Bot size={48} />
          )}
        </div>

        <h2 className="agent-title">{agent.name}</h2>
        <p className="agent-description">{agent.description}</p>

        <div className="agent-metadata">
          <span className="category-badge">{agent.category}</span>
          <span className="creator-text">by {agent.creator || 'You'}</span>
        </div>

        <div className="action-buttons d-flex justify-content-between">
          <div className='customize-btns'>
            {canEdit && (
              <OverlayTrigger
                placement="top"
                trigger={['hover']}
                overlay={<Tooltip>Edit agent</Tooltip>}
              >
                <button
                  className="action-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(agent);
                  }}
                >
                  <Edit2 size={20} />
                </button>
              </OverlayTrigger>
            )}

            {canDelete && (
              <OverlayTrigger
                placement="top"
                trigger={['hover']}
                overlay={<Tooltip>Delete agent</Tooltip>}
              >
                <button
                  className="action-button delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(agent);
                  }}
                >
                  <Trash2 size={20} />
                </button>
              </OverlayTrigger>
            )}

            <OverlayTrigger
              placement="top" 
              trigger={['hover']}
              overlay={<Tooltip>{isFavorite ? "Remove from favorites" : "Add to favorites"}</Tooltip>}
            >
              <button
                className={`action-button ${isFavorite ? 'favorite' : ''}`}
                onClick={handleFavoriteToggle}
                disabled={isTogglingFavorite}
              >
                {isTogglingFavorite ? (
                  <div className="favorite-spinner" />
                ) : (
                  <Star
                    size={20}
                    className={isFavorite ? 'fill-current' : ''}
                  />
                )}
              </button>
            </OverlayTrigger>
          </div>
          
          <div className='start-chat-wrap'>
            <button
              className="start-chat-button"
              onClick={handleStartChat}
            >
              <MessageSquarePlus size={16} /> Start Chat
            </button>
          </div>
        </div>
      </div>

      <Modal 
        show={showPricingModal} 
        onHide={() => setShowPricingModal(false)} 
        keyboard={false} 
        className={loginState ? "loginState" : "upgradeModal"}
      >
        <Modal.Body className="text-center">
          <InlinePricing />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AgentCard;