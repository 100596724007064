import {React, useState, useEffect, useContext} from "react";
import { Button, Nav, Navbar, NavDropdown, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Route, useLocation, Link  } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext"; 
import { useSidebar } from '../../../context/SidebarContext'; 
import { FcGoogle} from "react-icons/fc";
import { RiFileList3Line, RiListCheck2} from "react-icons/ri"; 
import commonServices from "../../../services/common.services";
import SearchBar from "../../shared/utilities/search-bar/search-bar";
import ToolsData from "../../../services/toolsData/ToolsData";
import "./Header.css";
import { LogoSvg, LogOutIcon, PricingIcon, ProfileIcon, SupportIcon, SideArrow, UpgradeIcon, TemplateIcon, PicIcon, TempIcon, MyContentIcon, ImageGeneratorIcon, NavDashboardIcon  } from "../../../assets/svg/SvgIcons";
import SidebarPanel from "../../dashboard/sidebar/SidebarPanel";

const Header = () => {
  const { toggleSidebar } = useSidebar();
  const { subscriber, setSubscriber } = useContext(SubscriberContext); 
  const [expanded, setExpanded] = useState(false);
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [usagePercentage, setUsagePercentage] = useState(0);
  const [userData, setUserData] = useState();
  const shouldShowDashboardLink = location.pathname.includes('blog-post') || location.pathname.includes('article-writer') || location.pathname.includes('pricing') || location.pathname.includes('profile');
  
  const [headVisible, setHeadVisible] = useState(true);
  
  useEffect(() => { 
    if (location.pathname.includes('/appsumo')) {
      setHeadVisible(false);
    } else {
      setHeadVisible(true);
    }
  }, [location]);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      //console.log(error.message);
    }
  }; 

  useEffect(() => {  
    if(subscriber){
      //console.log(subscriber)
      const now = (subscriber.totalWords / subscriber.wordsLimit) * 100; 
      setUsagePercentage(Math.round(now))
    }  
  }); 
 useEffect(async () => {
  if(user){
    //console.log(user)
    const getUserData = await commonServices.getUsageData(user.uid);  
    setUserData(getUserData.data()) 
  }
 },[])
  const handleSelect = (eventKey) => {
    // alert(`selected ${eventKey}`)
    navigate(`/${eventKey}`);
  };

  
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 570);
    }; 
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <> 
    <div className="main-header row">
      <div className="col">
        <Link to="/" className="logo"><LogoSvg/> </Link> 
        </div>
      <div className="col">
        <div className="head-center"> 
      <div className="limitedTimeOffer">
        </div>
                                {shouldShowDashboardLink && (
                                    <Link to="/" className="dashboardLink" onClick={() => setExpanded(false)}>
                                      <NavDashboardIcon/>
                                      <span className="ml-2">Dashboard</span>
                                    </Link>
                                  )}
                                  {/* <SearchBar toolsData={ToolsData} /> */}
                                  </div>
      </div>
      <div className="col">
      <div className="head-right"> 
      {subscriber && 
          <div className="wordsUsed float-left">
            <span><i className="wIcon">w</i><p>{subscriber.totalWords}</p></span>   
          </div>
        }
        
      <div className="hamburger-menu" onClick={toggleSidebar}>|||</div>  
      <Navbar className="header-nav float-right"> 
      <Nav className="myprofile-link">   
        {user && 
          <NavDropdown 
            title={
              <span className="profileImgWrap">
                <span className="profile-img"> 
                  {user && user.photoURL && <img src={user.photoURL} alt="profile" width="100%" />} 
                  {user && !user.photoURL && <PicIcon/>}  
                </span>
                <span className="linkName">My Account </span>
              </span>
            } 
            align="end" id="basic-nav-dropdown">
            <NavDropdown.Item eventKey="profile"><ProfileIcon/>
            <Link to="/profile">
        My Profile
    </Link>
    </NavDropdown.Item>
            <NavDropdown.Item eventKey="pricing"><PricingIcon/>
            <Link to="/pricing">
            Pricing Plans
    </Link>
    </NavDropdown.Item>
            {/* <NavDropdown.Item eventKey="appsumo"><PricingIcon/>AppSumo</NavDropdown.Item> */}
            <NavDropdown.Item href="https://wordkraft.ai/support/" target="_blank"><SupportIcon/>Email Support</NavDropdown.Item> 
            <NavDropdown.Item onClick={handleLogout}><LogOutIcon/> Log out</NavDropdown.Item>
          </NavDropdown>
        }

        {!user && headVisible && 
          <Link variant="primary" className="head-login-btn btn-primary" to="/login">
            Login
          </Link>
        } 
      </Nav> 
      </Navbar>
      </div>
      </div>
    </div>
    </>
  );
};

export default Header;  