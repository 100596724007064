// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"; 
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getFunctions } from 'firebase/functions';
import { writeBatch } from '@firebase/firestore'; // Import writeBatch
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZpjiPmKEzyPqfuuDqo-ylm4ukBejGKfA",
  authDomain: "wordkraft-bb683.firebaseapp.com",
  projectId: "wordkraft-bb683",
  storageBucket: "wordkraft-bb683.appspot.com",
  messagingSenderId: "296561500870",
  appId: "1:296561500870:web:098c13918e149e812fedc7"
};
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const batch = writeBatch(db); // Use writeBatch
export default app; 
export { storage };