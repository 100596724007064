import { React, useState } from "react";
import { getFunctions } from "firebase/functions";
import Select from 'react-select';
import ReactCountryFlag from "react-country-flag";
import "./KeywordResearch.css";
import { Container, Button, Row, Col, Form, FormControl, FormGroup } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { CompititionIcon, CpcIcon, TrendIcon, VolumeIcon } from "../../../assets/svg/SvgIcons";
import Loader2 from "../../shared/utilities/loader2/Loader2";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router";
import { countryList } from "./countryList";
import SortableKeywordTable from './SortableKeywordTable';

const KeywordResearch = () => {
  const [keywords, setKeywords] = useState("");
  const [country, setCountry] = useState("us");
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const functions = getFunctions();
  const navigate = useNavigate();

  const [relatedKeywords, setRelatedKeywords] = useState([]);
  const [keywordQuestions, setKeywordQuestions] = useState();
  const [loadingRel, setLoadingRel] = useState(false);
  const [loadingLong, setLoadingLong] = useState(false);
  const [loadingQuest, setLoadingQuest] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = relatedKeywords?.slice(indexOfFirstItem, indexOfLastItem) || [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(relatedKeywords?.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }
  
    return (
      <nav>
        <ul className="pagination justify-content-center mt-4">
          {pageNumbers.map(number => (
            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
              <button 
                onClick={() => paginate(number)} 
                className="page-link"
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  // const renderKeywordTable = (data) => (
  //   <table className="related-table">
  //     <thead>
  //       <tr>
  //         <th width="35%">Keyword</th>
  //         <th width="15%">Volume</th>
  //         <th width="12%">Competition</th>
  //         <th width="15%">KD</th>
  //         <th width="12%">High CPC</th>
  //         <th width="11%">Trend</th>
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {data.map((item, index) => {
  //         // Calculate the trend based on monthly search volumes
  //         const trendData = item.monthly_search_volumes || [];
  //         const trendChange = trendData.length > 1 
  //           ? ((trendData[trendData.length - 1].searches - trendData[0].searches) / trendData[0].searches) * 100
  //           : 0;
  
  //         const isTrendPositive = trendChange > 0;
  //         const trendArrow = isTrendPositive ? '↑' : '↓';
  //         const trendColor = isTrendPositive ? 'green' : 'red';
  
  //         return (
  //           <tr key={index}>
  //             <td>
  //               <Form.Check
  //                 id={`related-checkbox-${index}`}
  //                 inline
  //                 label={item.keyword}
  //                 type="checkbox"
  //                 onChange={(e) => handleSelectChange(e, item.keyword)}
  //               />
  //             </td>
  //             <td>
  //               <span>{item.avg_monthly_searches}</span>
  //             </td>
  //             <td>
  //               <span className={item.competition_value?.toLowerCase()}>{item.competition_index}</span>
  //             </td>
  //             <td>
  //               <span className={item.competition_value?.toLowerCase()}>{item.competition_value}</span>
  //             </td>
  //             <td>
  //               <span>{item['High CPC']}</span>
  //             </td>
  //             <td>
  //               <span className="trend-data" style={{ color: trendColor }}>
  //                 {trendArrow} {Math.abs(trendChange).toFixed(1)}%
  //               </span>
  //             </td>
  //           </tr>
  //         );
  //       })}
  //     </tbody>
  //   </table>
  // );

  const renderKeywordTable = (data) => (
    <SortableKeywordTable 
      data={data}
      onSelectChange={handleSelectChange}
      currentPage={currentPage}
      itemsPerPage={itemsPerPage}
      selectedKeywords={selectedTitles}  // Pass the selected keywords array
    />
  );
  const renderTable = (data) => (
    <ul>
      {data.map((item, index) => (
        <li key={index}>
          <Form.Check
            id={`custom-radio-${index}`}
            inline
            label={item}
            name="custom-radio"
            type="radio"
            onChange={(e) => handleSelectBlogTitle(e, item)}
          />
        </li>
      ))}
    </ul>
  );

  const [blogTitle, setBlogTitle] = useState(null);
  const handleSelectBlogTitle = (e, item) => {
    setBlogTitle(item);
  };

  const [selectedTitles, setSelectedTitles] = useState([]);

  const handleSelectChange = (e, keyword) => {
    if (e.target.checked) {
      setSelectedTitles(prev => [...prev, keyword]);
    } else {
      setSelectedTitles(prev => prev.filter(title => title !== keyword));
    }
  };

  const [myObject, setMyObject] = useState();
  const createArticle = () => {
    const getData = {
      keywords: selectedTitles,
      title: blogTitle
    };
    setMyObject(getData);
    navigate('/article-writer', { state: { myProp: getData } });
  };

  const renderBarChart = () => {
    if (!results || !results.trend) {
      return;
    }

    const data = {
      labels: results.trend.map((item) => (item.month.slice(0, 3) + " " + item.year)),
      datasets: [
        {
          label: 'Volume',
          data: results.trend.map((item) => item.value),
          backgroundColor: '#6a3dfd78',
          borderColor: '#6a3dfd78',
          borderWidth: 0,
          barThickness: 15
        }
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    return <Bar data={data} options={options} className="bar-chart" />;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setLoadingRel(true);
    setLoadingLong(true);
    setLoadingQuest(true);
  
    fetchLongSuggestedTitles();
    setResults();
    setRelatedKeywords();
  
    const makeRequest = async (retryCount = 0) => {
      try {
        const local = 'http://localhost:3001/';
        const dev = 'https://wk-server.vercel.app/';
        const endpoint = country === 'gl' ? 'global-volume' : 'keyword-research';
        
        const response = await fetch(
          `${dev}api/${endpoint}?keyword=${encodeURIComponent(keywords)}${country !== 'gl' ? `&country=${country}` : ''}`
        );
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        console.log('API Response:', data);
  
        if (country === 'gl') {
          const globalData = data?.['Global Keyword Data']?.[0];
          
          if (!globalData) {
            throw new Error('No global keyword data found');
          }
  
          const transformedGlobalData = {
            vol: globalData.avg_monthly_searches || 0,
            cpc: {
              currency: '$',
              value: globalData['High CPC']?.replace('$', '') || globalData['High CPC'] || '0.00'
            },
            competition: globalData.competition_index || 0,
            competitionVal: globalData.competition_value || 'LOW', 
            trend: globalData.monthly_search_volumes?.map(item => ({
              month: item.month,
              year: item.year,
              value: item.searches,
            })) || [],
          };
  
          setResults(transformedGlobalData);
          
          const relatedKeywords = data?.['Related Keyword Data (Global)'] || [];
          const sortedArray = relatedKeywords.sort((a, b) => {
            const aVolume = a.monthly_search_volumes?.reduce((sum, item) => sum + item.searches, 0) || 0;
            const bVolume = b.monthly_search_volumes?.reduce((sum, item) => sum + item.searches, 0) || 0;
            return bVolume - aVolume;
          });
  
          const subsetArray = sortedArray.slice(0, 200);
          setRelatedKeywords(subsetArray);
  
        } else {
          const resultArray = data.result || data.results || data;
          if (!resultArray || !Array.isArray(resultArray)) {
            throw new Error('No keyword data found');
          }
  
          if (resultArray.length === 0) {
            throw new Error('No keyword data found');
          }
  
          const sortedArray = resultArray.sort((a, b) => {
            const aVolume = a.monthly_search_volumes?.reduce((sum, item) => sum + item.searches, 0) || 0;
            const bVolume = b.monthly_search_volumes?.reduce((sum, item) => sum + item.searches, 0) || 0;
            return bVolume - aVolume;
          });
  
          const subsetArray = sortedArray.slice(0, 200);
  
          const transformedData = {
            vol: subsetArray[0].avg_monthly_searches || 0,
            cpc: {
              currency: '$',
              value: subsetArray[0]['High CPC']?.replace('$', '') || 0,
            },
            competition: subsetArray[0].competition_index || 0,
            competitionVal: subsetArray[0].competition_value || 'Easy',
            trend: subsetArray[0].monthly_search_volumes?.map(item => ({
              month: item.month,
              year: item.year,
              value: item.searches,
            })) || [],
          };
  
          setResults(transformedData);
          setRelatedKeywords(subsetArray);
        }
  
      } catch (error) {
        console.error(`Error fetching keyword data (attempt ${retryCount + 1}):`, error);
        
        if (retryCount < 1) {
          // If this was the first attempt, try one more time
          console.log('Retrying request...');
          toast.loading('Retrying search...', { duration: 2000 });
          return makeRequest(retryCount + 1);
        } else {
          // If this was the retry attempt, show error
          toast.error('Error fetching keyword data after retry');
          throw error;
        }
      }
    };
  
    try {
      await makeRequest();
    } catch (error) {
      console.error('All attempts failed:', error);
    } finally {
      setLoading(false);
      setLoadingRel(false);
      setLoadingLong(false);
      setLoadingQuest(false);
    }
  };

  const updateTitlesBasedOnKeywords = async () => {
    if (selectedTitles.length === 0) {
      toast.error("Please select at least one keyword first");
      return;
    }
  
    setLoadingQuest(true);
    setKeywordQuestions(null); // Clear existing titles
  
    const param = {
      type: "questionsKeywords",
      about: selectedTitles.join(", "),
      language: "English",
      wcount: 250,
      creative: 0.85,
      num: 1,
    };
  
    try {
      const kwObj = await fetchData(param);
      setKeywordQuestions(kwObj.kwArray);
    } catch (error) {
      console.error("Error updating titles:", error);
      toast.error("Error updating titles");
    } finally {
      setLoadingQuest(false);
    }
  };


  const fetchData = async (params) => {
    try { 
      const functionUrl = "https://us-central1-wordkraft-bb683.cloudfunctions.net/getAIkeywords";

      const response = await fetch(functionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const resdata = await response.json();
      let oldOutLine = resdata.data;
      let sentences = oldOutLine.split(/\r?\n/);
      let updatedList = [];
      sentences.filter(removeNum);

      function removeNum(value) {
        updatedList.push(value.toString().replace(/\d+.\s/, "").replace(/['"]+/g, ""));
      }

      let newOutLine = updatedList.filter(removeEmpty);

      function removeEmpty(value) {
        return value.length > 6;
      }

      return {
        kwType:
          params.type === "relatedKeywords"
            ? "relatedKw"
            : params.type === "longTailKeywords"
              ? "longKw"
              : "questionsKw",
        kwArray: newOutLine,
      };
    } catch (error) {
      console.error("Error fetching AI data:", error);
    } finally { 
    }
  };

  const fetchLongSuggestedTitles = async () => {
    const param = {
      type: "questionsKeywords",
      about: keywords,
      language: "English",
      wcount: 250,
      creative: 0.85,
      num: 1,
    };

    const kwObj = await fetchData(param);
    setKeywordQuestions(kwObj.kwArray);
    setLoadingQuest(false);
  };

 
  const handleChangeKeywords = (event) => {
    setKeywords(event.target.value);
  };

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  const countryOptions = countryList.map((country) => ({
    value: country.code,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ReactCountryFlag
          countryCode={country.code.toUpperCase()}
          svg
          style={{
            width: '1.1em',
            height: '1.1em',
            marginRight: '0.5em',
          }}
        />
        {country.name}
      </div>
    ),
  }));

  return (
    <div className="main-layout">
      <Container className="keywordResearch">
        <div className="search-container mb-5">
          <section className="header-txt m-0 p-0 text-center">
          <h2 className="text-center">Keyword Research</h2>
          <p>Get accurate and real-time data with suggested titles for your blog post or article from <b>246 countries</b>.</p>
          </section>
          <Row className="search-row mt-3 mb-4">
            <div className="keywordSearchForm">
              <Form onSubmit={handleSubmit}>
                <Row className="justify-content-md-center">
                  <Col>
                    <FormGroup>
                      <FormControl
                        type="text"
                        value={keywords}
                        onChange={handleChangeKeywords}
                        className="search-input"
                        placeholder="Eg: Green tea"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <Select
                      options={countryOptions}
                      value={countryOptions.find(option => option.value === country)}
                      onChange={(selectedOption) => setCountry(selectedOption.value)}
                      className="select-input"
                      styles={{
                        control: (provided) => ({
                          ...provided, 
                          height: '45px',
                          minHeight: '45px',
                        }),
                      }}
                    />
                  </Col>
                  <Col>
                    <Button variant="primary" type="submit" disabled={!keywords}>
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Row>
        </div>
        {loading && <div><Loader2 /></div>}

        {results && !loading &&
          <div className="keywordResult">
            <div className="keywordOverview rowSection mb-4">
              <Row className="overviewCard">
                <Col className="col-sm-12 col-md-6">
                  <h2>Keyword Overview: <span className="keyWord">{keywords}</span></h2>
                  <div className="keyOverview">
                    <Row className="firstRow">
                      <div className="col-sm-12 col-md-6 borderBottom borderRight">
                        <Row className="card">
                          <Col className="col-md-10">
                            <h6>Volume</h6>
                            <h4>{results.vol}</h4>
                          </Col>
                          <Col className="text-right kIcon volIcon">
                            <VolumeIcon />
                          </Col>
                        </Row>
                      </div>
                      <div className="col-sm-12 col-md-6 borderBottom">
                        <Row className="card">
                          <Col className="col-md-10">
                            <h6>CPC</h6>
                            <h4>{results.cpc.currency}{results.cpc.value}</h4>
                          </Col>
                          <Col className="text-right kIcon cpcIcon">
                            <CpcIcon />
                          </Col>
                        </Row>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-sm-12 col-md-6 borderRight">
                        <Row className="card">
                          <Col className="col-md-10">
                            <h6>Competition</h6>
                            <h4><span className={results.competitionVal?.toLowerCase()}>{results.competition}</span></h4>
                          </Col>
                          <Col className="text-right kIcon compIcon">
                            <CompititionIcon />
                          </Col>
                        </Row>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <Row className="card">
                          <Col className="col-md-10">
                            <h6>Keyword Difficulty</h6>
                            <h4 className="kd">
                             <span className={results.competitionVal?.toLowerCase()}>{results.competitionVal}</span> 
                            </h4>
                          </Col>
                          <Col className="text-right kIcon trendIcon">
                            <TrendIcon />
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </div>
                </Col>
                <Col className="col-md-6">
                  <h2>Keyword Trend</h2>
                  {results && renderBarChart()}
                </Col>
              </Row>
            </div>

            <div className="keywordIdea rowSection m-0">
              <div className="float-left w-100">
                {/* <h2 className="float-left">Keyword Ideas</h2> <div className="createArticleBtn float-right"></div> */}
              </div>
              <Row className="secondRow mt-1">
                <div className="col-sm-12 col-md-12">
                  <h3>Related Keywords</h3>
                  <div className="card p-4">
                    {loadingRel && <div><Loader2 /></div>}
                    {currentItems && !loadingRel && <>{renderKeywordTable(currentItems)}{renderPagination()}</>}
                  </div>
                </div>
              </Row>
              <Row>
                <div className="mt-5 col-sm-12 col-md-8">
                   <div className="d-flex justify-content-between align-items-center">
                      <h3>Suggested Article Titles</h3>
                      <Button 
                        variant="outline-primary"
                        onClick={updateTitlesBasedOnKeywords}
                        disabled={selectedTitles.length === 0 || loadingQuest}
                        className="mb-2"
                      >
                        {loadingQuest ? 'Updating...' : 'Update Titles'}
                      </Button>
                    </div>
                    <div className="card p-4">
                    {loadingQuest && <div><Loader2 /></div>}
                    {keywordQuestions && !loadingQuest && <>{renderTable(keywordQuestions)}</>}
                  </div>
                </div>
                <div className="mt-5 col-sm-12 col-md-4">
                  <h3><br /></h3>
                  <div className="card p-4 seo-selected">
                    <h3 className="mb-4">Generate SEO optimized Article</h3>
                    <div className="seo-blog-title mb-3">
                      <strong>Article Title</strong> <br />
                      {!blogTitle && <p><i>No title selected</i></p>}
                      {blogTitle && <p>{blogTitle}</p>}
                    </div>
                    <div className="seo-keywords">
                      <strong>Keywords</strong> <br />
                      {(selectedTitles.length < 1) && <p><i>No keywords selected</i></p>}
                      {selectedTitles && <p>{selectedTitles.join(", ")}</p>}
                    </div>
                    <Button variant="primary" className="mt-3" onClick={createArticle} disabled={(selectedTitles.length < 1) && (!blogTitle)}>⁺₊✧  Generate Article</Button>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        }      </Container>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default KeywordResearch;