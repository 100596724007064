import React, { useState } from 'react';
import { Form, InputGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SearchBar = ({ toolsData }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (value.length > 0) {
            const results = Object.values(toolsData).flat().filter(tool =>
                tool.title.toLowerCase().includes(value.toLowerCase())
            );
            setSearchResults(results);
            setDropdownVisible(true);
        } else {
            setSearchResults([]);
            setDropdownVisible(false);
        }
    };

    const handleSelect = () => {
        setSearchTerm('');
        setSearchResults([]);
        setDropdownVisible(false);
    };

    return (
        <div className="search-bar">
            <InputGroup>
                <InputGroup.Text>
                    <span>🔍</span>  {/* Emoji as search icon */}
                </InputGroup.Text>
            <Form.Control
                type="text"
                placeholder="Search from 435 tools..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
               </InputGroup>
            {dropdownVisible && searchResults.length > 0 && (
                <Dropdown.Menu show>
                    {searchResults.map((tool, index) => (
                        <Dropdown.Item key={index} as={Link} to={`/ai-tools/?prompt=${tool.prompt}`} onClick={handleSelect}>
                            {tool.icon} {tool.title}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            )}
        </div>
    );
};

export default SearchBar;
