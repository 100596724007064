import React, {useRef, useMemo} from "react";
import JoditEditor from "jodit-react";



const RichTextEditor = ({changeValue, blurValue, defaultContent}) => {
    const editor = useRef(null)     
    const config2 = {
        buttons: "ul,ol,|,fontsize,,link,|,align"
      };
    
    return (
        <JoditEditor 
          
            ref={editor}  
            value={defaultContent}
            config={config2}
              onChange={(content) => changeValue(content)} 
          // onChange={changeValue} 
           onBlur={blurValue}
        />
    )
}

export default RichTextEditor