import React, { useState, useEffect } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import CommonDataServices from "../../services/common.services";
import SearchBar from "./../shared/utilities/search-bar/search-bar"; 
import ToolsData from "../../services/toolsData/ToolsData";
import "./Dashboard.css";

const Dashboard = () => {
  const { user } = useUserAuth();
  const [firstName, setFirstName] = useState('');

  useEffect(() => { 
    const fetchData = async () => {
      if(user){
        const userD = await CommonDataServices.getUsageData(user.uid);
        const userData = userD.data(); 
        if (userData) {
          if ('firstName' in userData) { 
            setFirstName(userData.firstName); 
          } else {
            setFirstName(user.displayName); 
          } 
        }
      } 
    };  
    fetchData();
  }, [user]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const groupedTools = groupByCategory(ToolsData);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  // Dropdown handler
  const handleDropdownChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const filteredTools =
    selectedCategory === "all"
      ? Object.values(groupedTools).flat()
      : groupedTools[selectedCategory];

  return (
    <>
      <Container className="main-layout templates">
     
        <div className="dashboard-head header-txt">
                    <h2 className="mt-2 mb-3 text-left">👋 Hey {user && firstName}  <span className="text-left dashCaption"> - Let's elevate your content creation.</span></h2>
           </div> 
         {/* Dropdown - Shown on Mobile */}
         <div className="category-dropdown d-block d-md-none">
          <select onChange={handleDropdownChange} value={selectedCategory} className="form-select form-select-sm mb-4">
            <option value="all">All Tools</option>
            {Object.keys(groupedTools).map((category) => (
              <option key={category} value={category}>
                {categoryEmojis[category]} {capitalizeFirstLetter(category)} Tools
              </option>
            ))}
          </select>

     
        </div>
        
        <div className="search-tools">
         <SearchBar toolsData={ToolsData} />
         </div>

        <div className="card-sort-btns mb-4">
          <Button
            variant="outline-primary"
            size="sm"
            className={selectedCategory === "all" ? "active" : ""}
            title="all"
            onClick={() => handleCategoryClick("all")}
          >
            All<span className="badge badge-light">{Object.values(groupedTools).flat().length}</span>
          </Button>
          {Object.keys(groupedTools).map((category) => (
            <Button
              key={category}
              variant="outline-primary"
              size="sm"
              className={selectedCategory === category ? "active" : ""}
              title={category}
              onClick={() => handleCategoryClick(category)}
            >
              {categoryEmojis[category]} {capitalizeFirstLetter(category)} Tools
              <span className="badge badge-light">{groupedTools[category].length}</span>
            </Button>
          ))}
        </div>

        <div className="card-grid-row tools-cards  mt-1">
          {filteredTools.map((tool, index) => (
            <Card title="template" key={index} className={selectedCategory}>
              <Link to={`/ai-tools/?prompt=${tool.prompt}`}>
                <div className="card-wrap">
                <div className="card-icon">
                           <div className="emoji">
                           {tool.icon}
                         </div>
                         </div>
                  <Card.Body>
                    <Card.Title>{tool.title}</Card.Title>
                    <Card.Text>{tool.caption}</Card.Text>
                  </Card.Body>
                </div>
              </Link>
            </Card>
          ))}
        </div>
      </Container>
    </>
  );
};

const groupByCategory = (toolsData) => {
  const allTools = Object.values(toolsData).flat();
  return allTools.reduce((acc, tool) => {
    const category = tool.category.toLowerCase();
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(tool);
    return acc;
  }, {});
};


const categoryEmojis = { 
  "advertising": "📢",
  "blog": "📰",
  "copywriting": "✍️",
  "ecommerce": "🛒",
  "email": "📧", 
  "film": "🎥",
  "hr": "👥", // For "Human Resources"
  "idea": "💡",
  "repurpose": "♻️",
  "image": "🖼️",
  "music": "🎶",
  "seo": "🔍", // For "Search Engine Optimization"
  "sales": "💰",
  "social media": "🌐",
  "support": "🛠️",
  "website": "🌍",
  "writing assistant": "📝",
  "youtube": "▶️", 
  "name & title":"🏷️",
  "resume-jobs":"📄",
  "travel planning":"✈️",
  "story poetry":"📖",  
  "education": "🎓",
  "podcast": "🎙️",
  "story writing":"📜",  
  "extra": "🌟"
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default Dashboard;
